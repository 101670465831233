interface LessonRequest {
    lessonName: string;
    lessonDescription: string;
    linkToResources: string | null;
    lessonTime: number;
}

const defaultLessonRequest: LessonRequest = {
    lessonName: '',
    lessonDescription: '',
    linkToResources: '',
    lessonTime: 0
};


export default LessonRequest;
export { defaultLessonRequest };