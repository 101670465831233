import React from "react";
import Question from "../models/models/Quiestion";

interface ViewQuestionCardProps {
    question: Question;
    index: number
}

const ViewQuizCard: React.FC<ViewQuestionCardProps> = ({question, index}) => {
    const variants: string[] = ["a", "b", "c", "d"];

    return (
        <li className="mb-10 ms-6">
            <span
                className="absolute text-gray-950 font-semibold flex items-center justify-center w-6 h-6 bg-accent-main-100 rounded-full -start-3 ring-8 ring-white">
                {
                    index + 1
                }
            </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                {question.question}
            </h3>
            <p className="mb-4 flex flex-col text-base font-normal text-gray-500 dark:text-gray-400">
                {
                    question.responses.map((r, index) => {
                        return <span>{variants[index]}) {r}</span>
                    })
                }
            </p>
        </li>
    );
}

export default ViewQuizCard;