import React from "react";
import {IoArrowBack, IoChevronBack} from "react-icons/io5";
import {Link, useLocation} from "react-router-dom";

interface BackButtonProps {
    slug: string;
    isFull: boolean;
    isSkinny?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({slug, isFull, isSkinny = false}) => {
    const location = useLocation();
    const value = slug.replaceAll("/", "");

    return (
        <Link
            className={"h-10 flex gap-2 justify-center items-center text-center " + (isFull ? "w-full " : "w-10 ") + (isSkinny ? "" : "font-medium rounded-lg text-sm bg-accent-main-500 text-white")}
            to={location.pathname.replace("/" + value, "")} replace={true}>
            {isSkinny ? <IoArrowBack/> : <IoChevronBack/>}
            {isFull ? <p>Inapoi</p> : <></>}
        </Link>
    )
}

export default BackButton

// 690