import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from "../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";
import {toast, Toaster} from "react-hot-toast";
import Live, {
    defaultLive
} from "../../../../../dashboardAdmin/functionals/components/livesAdmin/functionals/models/models/Live";
import LivesService from "../service/LivesService";
import {Link, useLocation, useParams} from "react-router-dom";
import moment from "moment/moment";
import {MdNotStarted} from "react-icons/md";
import {IoChevronBack} from "react-icons/io5";
import BackButton from "../../../../../../commons/component/BackButton";
import {join} from "lodash";

export default function ViewLive() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const liveService = new LivesService();
    const {liveSlug} = useParams<{ liveSlug: string }>()
    const [live, setLive] = useState<Live>(defaultLive);
    const [isOn, setIsOn] = useState<boolean>(false);
    const location = useLocation();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    //Adfaber2024#@
    useEffect(() => {
        if (liveSlug === undefined) return;

        setIsLoading(true);
        liveService.getLive(liveSlug ?? '')
            .then((response) => {
                setLive(response);
            })
            .catch((_) => toast.error("Live-ul nu este disponibil"))
            .finally(() => setIsLoading(false));
    }, [liveSlug]);

    useEffect(() => {
        const checkLiveStart = () => {
            const currentTime = new Date();
            if (new Date(live.liveStart).getTime() <= currentTime.getTime()) {
                setLive(prev => ({...prev, on: true}));
                joinLive();
                clearInterval(intervalRef.current!);
            }
        };

        intervalRef.current = setInterval(checkLiveStart, 1000);

        return () => clearInterval(intervalRef.current!);
    }, [live.liveStart]);

    useEffect(() => {
        if (!isOn || !liveSlug) return;

        const intervalId = setInterval(() => {
            liveService.saveTime(liveSlug).catch(_ => console.log("Error saving time"));
        }, 60 * 1000);

        return () => clearInterval(intervalId);
    }, [isOn, liveSlug]);

    const joinLive = () => {
        liveService.join(liveSlug ?? '')
            .then((response) => {
                if (response.success) {
                    setIsOn(true);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => console.log(error));
    };


    return (
        <>
            <div><Toaster/></div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Live-uri", live.liveTitle ?? '']}/>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between px-3 md:px-8 mt-4 ">
                <BackButton slug={live.slug} isFull={false}/>
                {!isLoading &&
                    <h1 className={"text-xl md:text-4xl font-semibold text-center w-full flex justify-center"}>
                        <span>{live.liveTitle}</span>
                        {live.on && <div className={"ml-1 w-2 h-2 bg-error rounded-full animate-pulse"}></div>}
                    </h1>
                }
            </div>
            <div className={"mt-8 px-3 md:px-8"}>
                <div className={"w-full bg-white rounded-lg shadow-lg p-5"}>
                    <iframe title={live.slug} src={live.liveLink}
                            allow="accelerometer; autoplay;"
                            className={"w-full aspect-video rounded-lg mb-4"}/>
                </div>
            </div>
            <div className="flex flex-wrap gap-5 my-5 mx-3 md:mx-8 pb-5">
                {
                    isLoading ?
                        <div className={"mt-8 justify-center w-full"}><LoadingPlaceholder/></div> :
                        <div className={"w-full bg-white rounded-lg shadow-lg p-5"}>
                            <p className={"text-xl"}>Descriere:</p>
                            <p className={"pb-3 text-gray-400"}>
                                {
                                    moment(live.liveStart).format('DD MMMM YYYY HH:mm') + " - " + moment(live.liveEnd).format('HH:mm')
                                }
                            </p>
                            <div className={"flex flex-col gap-2"}>
                                <div dangerouslySetInnerHTML={{__html: live.description}}></div>
                            </div>
                        </div>
                }
            </div>
        </>
    );
}