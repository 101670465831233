import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import Live from "../../../../../dashboardAdmin/functionals/components/livesAdmin/functionals/models/models/Live";
import GeneralResponse from "../../../../../../commons/environment/models/GeneralResponse";

export default class LivesService {

    async getMyLives(): Promise<Live[]> {
        const response: AxiosResponse<Live[]> = await axios.get(apiRoutes.listMyLives, {withCredentials: true});
        return response.data;
    }

    async getLive(slug: string): Promise<Live> {
        const response: AxiosResponse<Live> = await axios.get(apiRoutes.rootLives + slug, {withCredentials: true});
        return response.data;
    }

    async join(liveSlug: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post(apiRoutes.joinLive.replace("SLUG", liveSlug), null, {withCredentials: true});
        return response.data;
    }

    async saveTime(liveSlug: string) {
        await axios.post(apiRoutes.saveTime.replace("SLUG", liveSlug), null, {withCredentials: true});
    }

    async generateAward(liveCode: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post(apiRoutes.generateAward + liveCode, null, {withCredentials: true});
        return response.data;
    }
}