import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "../../commons/component/header/views/Header";
import {AuthProvider} from "../../commons/functionals/authLogic/AuthContext";
import Home from "../home/views/Home";
import LoginPage from "../login/views/LoginPage";
import RegisterStudentsPage from "../register/views/RegisterStudentsPage";
import VerifyTokenPage from "../verify-token/views/VerifyTokenPage";
import DashboardPage from "../dashboard/views/DashboardPage";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import Error404 from "../error404/views/Error404";
import DashboardAdminPage from "../dashboardAdmin/views/DashboardAdminPage";
import RegisterProfessorsPageMD from "../register/views/RegisterProfessorsPageMD";
import RegisterParentPage from "../register/views/RegisterParentPage";
import AdminRoute from "../../commons/functionals/authLogic/AdminRoute";
import PrivateRoute from "../../commons/functionals/authLogic/PrivateRoute";
import DashboardAdminRouter from "../dashboardAdmin/functionals/router/DashboardAdminRouter";
import DashboardUserRouter from "../dashboard/router/DashboardUserRouter";
import Maintenance from "../home/views/Maintenance";
import PasswordRecoveryStart from "../passwordRecovery/views/PasswordRecoveryStart";
import PasswordRecoveryEnd from "../passwordRecovery/views/PasswordRecoveryEnd";
import RegisterProfessorsPageRO from "../register/views/RegisterProfessorsPageRO";

function App() {

    return (
        <AuthProvider>
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path={ApplicationRoutes.root} element={<Home/>}/>
                    <Route path={ApplicationRoutes.maintenance} element={<Maintenance/>}/>
                    <Route path={ApplicationRoutes.login} element={<LoginPage/>}/>
                    <Route path={ApplicationRoutes.passwordRecoveryStart} element={<PasswordRecoveryStart/>}/>
                    <Route path={ApplicationRoutes.passwordRecoveryEnd} element={<PasswordRecoveryEnd/>}/>
                    <Route path={ApplicationRoutes.registerStudents} element={<RegisterStudentsPage/>}/>
                    <Route path={ApplicationRoutes.registerProfessorsRo} element={<RegisterProfessorsPageRO/>}/>
                    <Route path={ApplicationRoutes.registerProfessorsMd} element={<RegisterProfessorsPageMD/>}/>
                    <Route path={ApplicationRoutes.registerParents} element={<RegisterParentPage/>}/>
                    <Route path={ApplicationRoutes.verify} element={<VerifyTokenPage/>}/>
                    {
                        // Protected Routes
                    }
                    <Route path={ApplicationRoutes.dashboard + DashboardUserRouter.entrance}
                           element={<PrivateRoute><DashboardPage/></PrivateRoute>}/>
                    {
                        // Admin Routes
                    }
                    <Route path={ApplicationRoutes.dashboardAdminRoot + DashboardAdminRouter.entrance}
                           element={<AdminRoute><DashboardAdminPage/></AdminRoute>}/>

                    <Route path={ApplicationRoutes.any} element={<Error404/>}/>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
