import React, {useEffect, useState} from "react";
import Award from "../models/Award";
import AwardsService from "../AwardsService";
import {toast, Toaster} from "react-hot-toast";
import Breadcrumbs from "../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingText from "../../../../../../commons/component/loading/views/LoadingText";
import LivesService from "../../../lives/functionals/service/LivesService";
import {BsDot} from "react-icons/bs";
import {FaDownload} from "react-icons/fa";
import {Link} from "react-router-dom";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import LoadingCardPlaceholder from "../../../../../../commons/component/loading/views/LoadingCardPlaceholder";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";

export default function ListAwards() {

    const [awards, setAwards] = useState<Award[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [componentLoading, setComponentLoading] = useState<boolean>(false);
    const [liveCode, setLiveCode] = useState<string>("");
    const awardsService = new AwardsService();

    useEffect(() => {
        setIsLoading(true);
        awardsService.getMyAwards()
            .then(response => setAwards(response))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, []);

    const generateAward = () => {
        setComponentLoading(true);
        new LivesService().generateAward(liveCode)
            .then((response) => toast.success(response.message))
            .catch((error) => toast.error(error.response.data.message))
            .finally(() => setComponentLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Diplomele mele"]}/>
            </div>
            <div className="px-3 md:px-8 w-full">
                <div>
                    <h1 className={"text-xl md:text-4xl font-semibold"}>Acestea sunt diplomele tale:</h1>
                </div>
            </div>
            <div className="px-3 md:px-8 w-full mt-5">
                <div>
                    <p className={"text-sm md:text-xl"}>Genereaza diploma pentru un live:</p>
                </div>
                <div className="mt-3 flex w-full gap-2 flex-col lg:flex-row">
                    <input
                        type="text"
                        placeholder="Cod-ul live-ului"
                        value={liveCode}
                        onChange={(e) => setLiveCode(e.target.value)}
                        className="px-3 py-2 w-full md:w-96 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    <button
                        disabled={componentLoading}
                        onClick={generateAward}
                        className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full lg:w-[200px]"}>
                        {!componentLoading ? "Genereaza diploma" : <LoadingText color={"#fff"}/>}
                    </button>
                </div>
            </div>
            <div className="flex flex-wrap px-3 md:px-8 w-full mt-5">
                {isLoading ?
                    <LoadingPlaceholder /> : awards.map((award, index) => (
                        <div key={index} className="p-2 w-full sm:w-1/2 md:w-1/3">
                            <div
                                className="border rounded-lg bg-white shadow-lg p-4 flex flex-col items-center justify-center">
                                <h3 className="w-full text-lg font-semibold text-center border-b pb-2 mb-2">{award.courseName ?? 'Un nume de curs foarte foarte lung'}</h3>
                                <div className={"flex justify-between w-full"}>
                                    <div>
                                        <p className="text-sm flex flex-row justify-center items-center">
                                            {award.name} <BsDot/> {award.school}
                                        </p>
                                        <p className="text-xs my-2">{new Date(award.creationDate).toLocaleDateString()}</p>
                                    </div>
                                    <Link
                                        to={apiRoutes.rootAwards + award.slug}
                                        className={"flex justify-center items-center text-white bg-accent-main-500 w-fit font-medium rounded-lg text-sm px-5 py-2.5 text-center"}
                                    >
                                        <FaDownload/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}