import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import GeneralResponse from "../../../../../../commons/environment/models/GeneralResponse";
import Course from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Course";
import UserCourseProgress from "../models/UserCourseProgress";

export default class UserCoursesService {

    async listMyCourses(term: string): Promise<Course[]> {
        const response: AxiosResponse<Course[]> = await axios.get(apiRoutes.listCoursesMine + `?name=${term}`, {withCredentials: true});
        return response.data;
    }

    async getCourse(slug: string): Promise<Course> {
        const response: AxiosResponse<Course> = await axios.get(apiRoutes.listCourseDetails + slug, {withCredentials: true});
        return response.data;
    }

    async getCourseProgress(slug: string): Promise<UserCourseProgress> {
        const response: AxiosResponse<UserCourseProgress> = await axios.get(apiRoutes.listCourseProgress.replace("COURSE_SLUG", slug), {withCredentials: true});
        return response.data;
    }

    async nextResource(slug: string): Promise<UserCourseProgress> {
        const response: AxiosResponse<UserCourseProgress> = await axios.get(apiRoutes.courseProgressNext.replace("COURSE_SLUG", slug), {withCredentials: true});
        return response.data;
    }

    async verifyResponses(quizSlug: string, answers: {
        question: string,
        response: number
    }[]): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post(apiRoutes.verifyQuiz.replace("SLUG", quizSlug), answers, {withCredentials: true});
        return response.data;
    }
}