import React from "react";
import {Route, Routes} from "react-router-dom";
import CoursesAdminRouter from "../functionals/components/coursesAdmin/functionals/router/CoursesAdminRouter";
import AdminRoute from "../../../commons/functionals/authLogic/AdminRoute";
import CoursesAdmin from "../functionals/components/coursesAdmin/views/CoursesAdmin";
import DashboardAdminRouter from "../functionals/router/DashboardAdminRouter";
import DashboardAdminActionsPage from "./DashboardAdminActionsPage";
import UsersAdminRoutes from "../functionals/components/usersAdmin/functionals/routes/UsersAdminRoutes";
import UsersAdmin from "../functionals/components/usersAdmin/views/UsersAdmin";
import LivesAdminRouter from "../functionals/components/livesAdmin/functionals/router/LivesAdminRouter";
import LiveAdmin from "../functionals/components/livesAdmin/views/LiveAdmin";
import ValidateProjectRouter
    from "../functionals/components/projectValidateAdmin/functionals/router/ValidateProjectRouter";
import ValidateProjectRoot from "../functionals/components/projectValidateAdmin/views/ValidateProjectRoot";

export default function DashboardAdminPage() {

    return (
        <div className={"bg-background-color h-screen"}>
            <Routes>
                <Route path={DashboardAdminRouter.root}
                       element={<AdminRoute><DashboardAdminActionsPage/></AdminRoute>}/>
                <Route path={DashboardAdminRouter.courses + CoursesAdminRouter.entrance}
                       element={<AdminRoute><CoursesAdmin/></AdminRoute>}/>
                <Route path={DashboardAdminRouter.users + UsersAdminRoutes.entrance}
                       element={<AdminRoute><UsersAdmin/></AdminRoute>}/>
                <Route path={DashboardAdminRouter.lives + LivesAdminRouter.entrance}
                       element={<AdminRoute><LiveAdmin/></AdminRoute>}/>
                <Route path={DashboardAdminRouter.projects + ValidateProjectRouter.entrance}
                       element={<AdminRoute><ValidateProjectRoot/></AdminRoute>}/>
            </Routes>
        </div>
    )
}