import React, {FormEvent, useState} from "react";
import LoadingText from "../../../commons/component/loading/views/LoadingText";
import RegisterProfessorRequest from "../functionals/models/RegisterProfessorRequest";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import {IoIosArrowRoundForward} from "react-icons/io";
import RegisterService from "../functionals/service/RegisterService";
import {Link} from "react-router-dom";


export default function RegisterProfessorsPageRO() {

    const [request, setRequest] = useState<RegisterProfessorRequest>({
        email: '',
        password: '',
        username: '',
        school: '',
        city: '',
        addressing: '',
        country: 'RO',
        phone: ''
    });
    const [error, setError] = useState<string | null>(null);
    const [componentLoading, setComponentLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const {name, value} = e.target;
        setRequest((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSexChange = (input: string) => {
        setRequest((prevData) => ({
            ...prevData,
            addressing: input,
        }));
    }

    const register = (event: FormEvent) => {
        event.preventDefault();
        if (request.addressing !== 'Domnul' && request.addressing !== 'Doamna')
            return;

        setComponentLoading(true);

        new RegisterService().registerProfessor(request).then((_) => {
            setError("");
            setComponentLoading(false);
        }).catch((error) => {
            setError(error.response.data.message);
            setComponentLoading(false);
        });
    }

    return (
        <div className={"flex justify-center items-center bg-background-color pt-[50px] md:pt-[70px] md:mb-[20px]"}>
            {
                error === "" ?
                    <div className={"text-3xl text-green-700"}>Am trimis un link de verificare pe email</div> :
                    <div className={"md:w-[500px] w-full p-10 bg-white md:rounded-xl md:shadow-xl"}>
                        <h1 className={"pb-4 text-3xl text-center"}>Inscrie-te pe platforma Adfaber</h1>
                        <p className={"text-xs text-gray-600 text-center"}>Creaza un nou cont de profesor pentru a avea
                            acces la
                            cursuri pentru profesori si elevi dar mai ales la Programul Eroii Internetului</p>
                        <form onSubmit={register} className={"flex justify-center items-align flex-col"}>
                            <div className="flex flex-col sm:flex-row sm:gap-6 sm:justify-evenly">
                                <div
                                    className={"w-full mt-3 border rounded cursor-pointer " + (request.addressing === 'Domnul' ? "border-accent-main-500 bg-white" : "border-gray-300 bg-gray-50")}
                                    onClick={() => handleSexChange('Domnul')}
                                >
                                    <div
                                        className={"w-full text-center py-4 " + (request.addressing === 'Domnul' ? "text-accent-main-500" : "text-gray-400")}>
                                        Domnul
                                    </div>
                                </div>
                                <div
                                    className={"w-full mt-3 border rounded cursor-pointer " + (request.addressing === 'Doamna' ? "border-accent-main-500 bg-white" : "border-gray-300 bg-gray-50")}
                                    onClick={() => handleSexChange('Doamna')}
                                >
                                    <div
                                        className={"w-full text-center py-4 " + (request.addressing === 'Doamna' ? "text-accent-main-500" : "text-gray-400")}>
                                        Doamna
                                    </div>
                                </div>
                            </div>
                            <input type="text" placeholder="Email"
                                   required={true}
                                   name="email"
                                   value={request.email}
                                   maxLength={50}
                                   onChange={handleInputChange}
                                   className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                            <input type="password" placeholder="Parola"
                                   required={true}
                                   name="password"
                                   value={request.password}
                                   onChange={handleInputChange}
                                   className="mt-5 mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                            <input type="text" placeholder="Numele tau"
                                   required={true}
                                   name="username"
                                   maxLength={20}
                                   value={request.username}
                                   onChange={handleInputChange}
                                   className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                            <input type="text" placeholder="Numele scolii"
                                   required={true}
                                   name="school"
                                   maxLength={100}
                                   value={request.school}
                                   onChange={handleInputChange}
                                   className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                            <input type="text" placeholder="Numarul tau de telefon"
                                   required={true}
                                   name="phone"
                                   maxLength={100}
                                   value={request.phone}
                                   onChange={handleInputChange}
                                   className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                            <input type="text" placeholder="Localitatea din care este scoala"
                                   required={true}
                                   name="city"
                                   maxLength={30}
                                   value={request.city}
                                   onChange={handleInputChange}
                                   className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                            <div
                                className={"my-5 text-center " + (error ? "text-error" : "text-black")}>
                                {
                                    error == null ? "" : error
                                }
                            </div>
                            <button type="submit"
                                    disabled={componentLoading}
                                    className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"}>
                                {!componentLoading ? "Register" : <LoadingText color={"#fff"}/>}
                            </button>
                            <Link to={ApplicationRoutes.login}
                                  className={"mt-4 flex justify-center items-center w-full text-center"}>
                                <span className={"mr-1"}>Ai un cont?</span>
                                <IoIosArrowRoundForward/>
                            </Link>
                        </form>
                    </div>
            }
        </div>
    );
}