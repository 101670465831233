import {useContext, useEffect, useRef, useState} from "react";
import HeaderService from "../functionals/services/Service";
import {useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../environment/AppRoutingName";
import {FaHome, FaUserCircle} from "react-icons/fa";
import {IoLogOut} from "react-icons/io5";
import {AuthContext} from "../../../functionals/authLogic/AuthContext";
import {IoIosArrowDown} from "react-icons/io";


export default function Header() {

    const {user, setUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const [isOpenUser, setIsOpenUser] = useState(false);
    const dropdownRefUser = useRef<HTMLDivElement>(null);

    const toggleDropdownUser = () => {
        setIsOpenUser(!isOpenUser);
    };

    const closeDropdown = (event: MouseEvent) => {
        if (dropdownRefUser.current && !dropdownRefUser.current.contains(event.target as Node)) {
            setIsOpenUser(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);

    function logout() {
        new HeaderService().logoutUser().then(_ => {
                setUser(null)
                setIsOpenUser(false);
                navigate(ApplicationRoutes.login);
            }
        );
    }

    function redirect(path: string) {
        setIsOpenUser(false);
        navigate(path);
    }

    return (
        <div
            className={"bg-accent-main-500 fixed text-white z-20 text-xl transition-all flex flex-row items-center justify-between h-[50px] px-[30px] py-2 w-full"}>
            <p className={"font-semibold cursor-pointer"} onClick={() => navigate('/')}>
                <img src="/images/logo.jpeg" className={"h-[50px]"}/>
            </p>
            {
                user === null ?
                    <div>
                        <p className={"cursor-pointer p-2"} onClick={() => redirect(ApplicationRoutes.login)}>
                            Portal logare
                        </p>
                    </div> :
                    <div>
                        <div className={"flex gap-5 text-sm"}>
                            <div className="relative inline-block text-left" ref={dropdownRefUser}>
                                <button
                                    onClick={toggleDropdownUser}
                                    type="button"
                                    className="flex justify-center items-center w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-accent-main-500 in focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                    <FaUserCircle className={"w-5 h-5"}/>
                                    <p className={"pl-2"}>{user?.username ?? 'Unnamed User'}</p>
                                    <IoIosArrowDown className={"ml-1 transition-all " + (isOpenUser ? "rotate-180" : "rotate-0")}/>
                                </button>

                                {isOpenUser && (
                                    <div
                                        className="origin-top-right absolute right-0 mt-2 w-[153px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                        <div className="py-1" role="menu" aria-orientation="vertical"
                                             aria-labelledby="options-menu">
                                            <div
                                                className="cursor-pointer flex justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                role="menuitem"
                                                onClick={() => redirect(ApplicationRoutes.dashboard)}>
                                                <FaHome className={"w-5 h-5"}/>
                                                <p className={"pl-2"}>Home</p>
                                            </div>
                                            <div
                                                className="cursor-pointer flex justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                role="menuitem"
                                                onClick={logout}>
                                                <IoLogOut className={"w-5 h-5"}/>
                                                <p className={"pl-2"}>Log out</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}