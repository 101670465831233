export default function Error404() {

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-gray-800">404</h1>
                <p className="mt-2 text-xl font-semibold text-gray-600">Pagina nu a fost gasita</p>
                <p className="mt-4 text-gray-500">Pagina pe care o cauti este posibil sa fie intr-un alt univers.</p>
            </div>
        </div>
    )
}