import React from "react";
import {Route, Routes} from "react-router-dom";
import CoursesAdminRouter from "../functionals/router/CoursesAdminRouter";
import ListCourses from "../functionals/components/ListCourses";
import CreateCourses from "../functionals/components/CreateCourses";
import AdminRoute from "../../../../../../commons/functionals/authLogic/AdminRoute";
import ViewCourse from "../functionals/components/ViewCourse";

export default function CoursesAdmin() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={CoursesAdminRouter.root} element={<AdminRoute><ListCourses/></AdminRoute>}/>
                <Route path={CoursesAdminRouter.view} element={<AdminRoute><ViewCourse/></AdminRoute>}/>
                <Route path={CoursesAdminRouter.create} element={<AdminRoute><CreateCourses/></AdminRoute>}/>
                <Route path={CoursesAdminRouter.edit} element={<AdminRoute><CreateCourses/></AdminRoute>}/>
            </Routes>
        </div>
    )
}