import React from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../../../../../commons/functionals/authLogic/PrivateRoute";
import CreateProjectRouter from "../functionals/router/CreateProjectRouter";
import ListProjectsAndCreate from "../functionals/components/ListProjectsAndCreate";

export default function CreateProjectRoot() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={CreateProjectRouter.root} element={<PrivateRoute><ListProjectsAndCreate/></PrivateRoute>}/>
            </Routes>
        </div>
    )
}