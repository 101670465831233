import Lesson from "./Lesson";
import Quiz from "./Quiz";
import UserResponse from "../../../../../../../../commons/component/header/functionals/models/UserResponse";

export default interface Course {
    slug: string;
    courseDescription: string;
    courseName: string;
    courseImage: string;
    courseResources: { object: (Lesson | Quiz), type: string }[];
    users: UserResponse[];
}

const defaultCourse: Course = {
    slug: '',
    courseName: '',
    courseImage: '',
    courseDescription: '',
    courseResources: [],
    users: []

}

export {defaultCourse};