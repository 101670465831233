import axios, {AxiosResponse} from "axios";
import LessonRequest from "../models/requests/LessonRequest";
import QuizzesRequest from "../models/requests/QuizzesRequest";
import CreateCourseRequest from "../models/requests/CreateCourseRequest";
import apiRoutes from "../../../../../../../commons/environment/EnvironmentRoutes";
import Lesson from "../models/models/Lesson";
import Quiz from "../models/models/Quiz";
import Course from "../models/models/Course";
import UserResponse from "../../../../../../../commons/component/header/functionals/models/UserResponse";
import GeneralResponse from "../../../../../../../commons/environment/models/GeneralResponse";

export default class CoursesService {

    async listCourses(): Promise<Course[]> {
        const response: AxiosResponse<Course[]> = await axios.get(apiRoutes.listCoursesAll, {withCredentials: true});
        return response.data;
    }

    async search(term: string): Promise<Course[]> {
        const response: AxiosResponse<Course[]> = await axios.get(apiRoutes.searchCoursesAll + term, {withCredentials: true});
        return response.data;
    }

    async getCourse(slug: string): Promise<Course> {
        const response: AxiosResponse<Course> = await axios.get(apiRoutes.listCourseDetails + slug, {withCredentials: true});
        return response.data;
    }

    async searchForCourseUsers(name: string, courseSlug: string): Promise<UserResponse[]> {
        const response: AxiosResponse<UserResponse[]> = await axios.get(apiRoutes.coursesSearchUsers.replace("SLUG", courseSlug) + `?name=${name}`, {withCredentials: true});
        return response.data;
    }

    async addUserToCourse(courseSlug: string, userSlug: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post(apiRoutes.assignCourses.replace("COURSE_SLUG", courseSlug).replace("USER_SLUG", userSlug), null, {withCredentials: true});
        return response.data;
    }

    async removeUserToCourse(courseSlug: string, userSlug: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.delete(apiRoutes.unassignCourses.replace("COURSE_SLUG", courseSlug).replace("USER_SLUG", userSlug), {withCredentials: true});
        return response.data;
    }

    async createLesson(request: LessonRequest): Promise<Lesson> {
        const response: AxiosResponse<Lesson> = await axios.post(apiRoutes.baseLesson, request, {withCredentials: true});
        return response.data;
    }

    async updateLesson(request: LessonRequest, lessonSlug: string): Promise<Lesson> {
        const response: AxiosResponse<Lesson> = await axios.put(apiRoutes.baseLesson + lessonSlug, request, {withCredentials: true});
        return response.data;
    }

    async removeLesson(slug: string): Promise<Lesson> {
        const response: AxiosResponse<Lesson> = await axios.delete(apiRoutes.baseLesson + slug, {withCredentials: true});
        return response.data;
    }

    async createQuiz(request: QuizzesRequest): Promise<Quiz> {
        const response: AxiosResponse<Quiz> = await axios.post(apiRoutes.baseQuiz, request, {withCredentials: true});
        return response.data;
    }

    async updateQuiz(request: QuizzesRequest, quizSlug: string): Promise<Quiz> {
        const response: AxiosResponse<Quiz> = await axios.put(apiRoutes.baseQuiz + quizSlug, request, {withCredentials: true});
        return response.data;
    }

    async removeQuiz(slug: string): Promise<Quiz> {
        const response: AxiosResponse<Quiz> = await axios.delete(apiRoutes.baseQuiz + slug, {withCredentials: true});
        return response.data;
    }

    async createCourse(request: CreateCourseRequest): Promise<void> {
        return await axios.post(apiRoutes.createCourse, request, {withCredentials: true});
    }

    async deleteCourse(slug: string): Promise<void> {
        return await axios.delete(apiRoutes.deleteCourse.replace("SLUG", slug), {withCredentials: true});
    }

}