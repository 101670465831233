import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {FaArrowLeftLong} from "react-icons/fa6";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import {IoAdd, IoClose, IoSearch, IoTrashBin} from "react-icons/io5";
import {toast, Toaster} from "react-hot-toast";
import UsersService from "../service/UsersService";
import ComplexUser, {defaultUser} from "../models/ComplexUser";
import LoadingPlaceholderPersons from "../../../../../../../commons/component/loading/views/LoadingPlaceholderPersons";
import ViewUserCard from "../../../coursesAdmin/functionals/iterables/ViewUserCard";
import ViewCourseCard from "../iterables/ViewCourseCard";
import Course from "../../../coursesAdmin/functionals/models/models/Course";
import {IoMdPersonAdd} from "react-icons/io";
import UserRoles from "../../../../../../../commons/component/header/functionals/models/UserRoles";
import CoursesService from "../../../coursesAdmin/functionals/service/CoursesService";
import PermissionsService from "../service/PermissionsService";
import PermissionList from "../iterables/PermissionsList";
import LivesAdminRouter from "../../../livesAdmin/functionals/router/LivesAdminRouter";
import BackButton from "../../../../../../../commons/component/BackButton";
import UsersAdminRoutes from "../routes/UsersAdminRoutes";

export default function ViewUser() {
    const [user, setUser] = useState<ComplexUser>(defaultUser);
    const [searchedCourses, setSearchedCourses] = useState<Course[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [permissions, setPermissions] = useState<{ groupName: string, permissions: string[] }[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSearchedCourses, setIsLoadingSearchedCourses] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const {userSlug} = useParams<{ userSlug: string }>();
    const usersService = new UsersService();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        if (userSlug?.length === 0 || userSlug === undefined) {
            navigate("/dashboard/admin/users");
            return;
        }

        usersService.getUserDetails(userSlug).then((response) => {
            setUser(response.data);
            new PermissionsService().getPermissions(response.data.role.name)
                .then((response) => {
                    setPermissions(convertPermissions(response));
                })
                .catch((_) => console.log());
        })
            .catch((_) => setError(true))
            .finally(() => {
                setIsLoading(false)
            });
    }, [userSlug]);

    const convertPermissions = (permissions: string[]): { groupName: string, permissions: string[] }[] => {
        const permissionGroups: { [groupName: string]: string[] } = {};

        permissions.forEach(permission => {
            const [_, groupName, permissionName, restOfPermission] = permission.split(".");
            const formattedPermissionName = (permissionName + (restOfPermission !== undefined ? ("." + restOfPermission) : "")).replace(/\./g, "-");
            if (!permissionGroups[groupName]) {
                permissionGroups[groupName] = [];
            }
            permissionGroups[groupName].push(formattedPermissionName);
        });

        return Object.entries(permissionGroups).map(([groupName, permissions]) => ({
            groupName,
            permissions
        }));
    };

    useEffect(() => {
        if (searchTerm.length !== 0) {
            const delayDebounceFn = setTimeout(() => searchCourse(searchTerm), 500);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const searchCourse = (courseName: string) => {
        if (userSlug !== undefined) {
            setIsLoadingSearchedCourses(true);
            usersService.searchForUsersCourses(courseName, userSlug)
                .then((response) => setSearchedCourses(response))
                .catch((error) => console.log(error))
                .finally(() => setIsLoadingSearchedCourses(false));
        }
    }

    const toggleModal = () => {
        setModal(!modal);
        setSearchTerm("");
    }

    const addCourse = (courseSlug: string) => {
        if (userSlug === undefined)
            return;

        new CoursesService().addUserToCourse(courseSlug, userSlug)
            .then((response) => {
                toast.success(response.message);
                let tempCourse: Course = searchedCourses.filter((u) => u.slug === courseSlug)[0];
                setSearchedCourses(searchedCourses.filter((c) => c.slug !== courseSlug));
                setUser(prevState => {
                    return {
                        ...prevState,
                        courses: [...prevState.courses, {
                            slug: tempCourse.slug,
                            name: tempCourse.courseName,
                            image: tempCourse.courseImage
                        }]
                    };
                });
            })
            .catch((error) => toast.error(error.response.data.message))
    }

    const deleteStudent = (studentSlug: string) => {
        console.log(studentSlug);
    }

    const deleteCourse = (courseSlug: string) => {
        if (userSlug === undefined)
            return;

        new CoursesService().removeUserToCourse(courseSlug, userSlug)
            .then((response) => {
                toast.success(response.message);
                setUser(prevState => {
                    return {
                        ...prevState,
                        courses: prevState.courses.filter((c) => c.slug !== courseSlug)
                    }
                });
            })
            .catch((error) => toast.error(error.response.data.message))
    }

    return (
        <div className={"px-3 md:px-8 bg-background-color h-full"}>
            <div><Toaster/></div>
            <Breadcrumbs paths={["Utilizatori", user.username]}/>
            <div className={"flex justify-between"}>
                <h1 className="flex justify-center items-center gap-3 text-xl md:text-4xl font-bold">
                    <BackButton slug={user.slug} isFull={false} isSkinny={true}/>
                    <p>Vizualizare utilizator</p>
                </h1>
            </div>
            <div className={"flex gap-5 my-5 h-fit md:h-full flex-col md:flex-row"}>
                {
                    isLoading ?
                        <LoadingPlaceholderPersons isCenter={true}/> :
                        <div className={"w-full lg:w-96 flex flex-col"}>
                            <div
                                className={"flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border h-fit"}>
                                <div className={"p-5"}>
                                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                        {
                                            user.username
                                        }
                                    </h5>
                                    {
                                        [
                                            {key: "Email", value: user.email},
                                            {key: "Telefon", value: user.phone ?? "-"},
                                            {key: "Scoala", value: user.school ?? "-"},
                                            {key: "Oras", value: user.city ?? "-"},
                                            {key: "Tara", value: user.country ?? "Fara tara"},
                                            {key: "User verificat", value: user.isVerified ? "DA" : "NU"}
                                        ].map((o: { key: string, value: string }, i) =>
                                            <p key={i}
                                               className={"font-normal text-gray-600 " + (i === 0 ? "mt-3" : "")}>
                                                {o.key + ": " + (o.value.length === 0 ? "-" : o.value)}
                                            </p>
                                        )
                                    }
                                </div>
                            </div>
                            <div
                                className={"mt-5 flex-col text-gray-700 bg-white shadow-md w-full rounded-xl bg-clip-border h-fit"}>
                                <div className={"p-5"}>
                                    <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                                        {user.role.name.replaceAll("_", " ").replace("ROLE", "")}
                                    </h5>
                                    {
                                        permissions.map((p, i) =>
                                            <PermissionList key={i} groupName={p.groupName}
                                                            permissions={p.permissions}/>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                }
                <div className={"w-full flex gap-2 flex-col md:flex-row"}>
                    <div
                        className={"w-full h-fit mb-5 " + (user.role.name === UserRoles.ROLE_STUDENT || user.role.name === UserRoles.ROLE_CHILD ? "hidden" : "")}>
                        <div
                            className={"w-full h-full p-5 flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border"}>
                            <h5 className="mb-5 text-2xl font-bold tracking-tight text-gray-900">
                                Studenti:
                            </h5>
                            <ol>
                                {
                                    isLoading ?
                                        <LoadingPlaceholderPersons isCenter={true}/> :
                                        user.students.length === 0 ?
                                            <li>Utilizatorul nu are niciun student</li> :
                                            user.students.map((u, i) =>
                                                <ViewUserCard user={u} key={i}
                                                              action={deleteStudent}
                                                              color={""}
                                                              icon={null}/>
                                            )
                                }
                            </ol>
                        </div>
                    </div>
                    <div className={"w-full h-fit mb-5"}>
                        <div
                            className={"w-full p-5 flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border"}>
                            <div className={"flex justify-between items-center mb-5"}>
                                <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                                    Cursurile utilizatorului:
                                </h5>
                                <button onClick={toggleModal}
                                        className={"bg-accent-main-500 p-2 rounded-lg text-white"}>
                                    <IoAdd/>
                                </button>
                            </div>
                            <ol>
                                {
                                    isLoading ?
                                        <LoadingPlaceholderPersons isCenter={true}/> :
                                        user.courses.length === 0 ?
                                            <li>Utilizatorul nu participa la niciun curs</li> :
                                            user.courses.map((c, i) =>
                                                <ViewCourseCard course={c} key={i} action={deleteCourse}
                                                                color={"bg-error"} icon={<IoTrashBin/>}/>)
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div id="default-modal"
                 className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full bg-black/80 " + (modal ? "flex" : "hidden")}>
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div
                            className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Adauga cursuri
                            </h3>
                            <button type="button"
                                    onClick={toggleModal}
                                    className="text-gray-400 bg-transparent hover:bg-accent-main-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="default-modal">
                                <IoClose/>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <form className="flex items-center max-w-sm mx-auto">
                                <label htmlFor="simple-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <div
                                        className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <IoSearch/>
                                    </div>
                                    <input type="text" id="simple-search"
                                           onChange={handleSearchChange}
                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5"
                                           placeholder="Cauta cursul dupa nume" required/>
                                </div>
                            </form>

                            {
                                isLoadingSearchedCourses ?
                                    <LoadingPlaceholderPersons isCenter={true}/> :
                                    searchedCourses.length === 0 ?
                                        <p>Nu exista cursuri. Cauta din nou</p> :
                                        searchedCourses.map((c, i) =>
                                            <ViewCourseCard color={"bg-green-500"}
                                                            course={{
                                                                slug: c.slug,
                                                                name: c.courseName,
                                                                image: c.courseImage
                                                            }}
                                                            key={i}
                                                            action={addCourse}
                                                            icon={<IoMdPersonAdd/>}/>
                                        )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}