import React from "react";

interface BreadcrumbsProps {
    paths: string[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({paths}) => {
    return (
        <div className={"pt-5 md:pt-14 pb-1"}>
            <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center" key={-1}>
                        <span
                            className="inline-flex items-center text-sm font-medium text-gray-700">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Acasa
                        </span>
                    </li>
                    {
                        paths.map((p, i) =>
                            <li key={i}>
                                <div className="flex items-center">
                                    <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="m1 9 4-4-4-4"/>
                                    </svg>
                                    <span
                                        className="ms-1 text-sm font-medium text-gray-700 md:ms-2">
                                    {
                                        p
                                    }
                                </span>
                                </div>
                            </li>
                        )
                    }
                </ol>
            </nav>
        </div>
    );
}

export default Breadcrumbs;