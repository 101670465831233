import React, {useState} from "react";
import Question
    from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Quiestion";

interface QuestionCardProps {
    question: Question;
    index: any;
    canEdit: boolean;
    selectResponse: (position: number) => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({index, question, canEdit, selectResponse}) => {

    const initials: string[] = ["a", "b", "c", "d"];
    const [selectedResponse, setSelectedResponse] = useState<number | undefined>(undefined);

    const selectResponseView = (response: number) => {
        if (!canEdit)
            return;

        selectResponse(response);
        setSelectedResponse(response);
    }

    return (
        <div key={index}
             className="w-full leading-tight outline-none text-start border-b mb-5 pb-5">
            <p className={"text-xl mb-2"}>
                <span className={"font-bold mr-2"}> Intrebarea {index + 1}:</span>
                <span>{question.question}</span>
            </p>
            <div className={"flex md:flex-row flex-col gap-2 md:gap-4"}>
                {
                    question.responses.map((variant, index) =>
                        <div onClick={() => selectResponseView(index)} key={index}
                             className={"border-4 cursor-pointer text-white bg-accent-main-500 w-full p-2 rounded-lg " + (selectedResponse === index ? "border-green-500" : "border-white")}>
                            {
                                initials[index] + ") " + variant
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default QuestionCard;