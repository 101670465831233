import React, {ReactNode, useContext} from "react";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import DashboardUserRouter from "../router/DashboardUserRouter";
import PrivateRoute from "../../../commons/functionals/authLogic/PrivateRoute";
import CoursesRoot from "../components/courses/views/CoursesRoot";
import CoursesUserRouter from "../components/courses/functionals/router/CoursesUserRouter";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import {IoHome} from "react-icons/io5";
import {PiProjectorScreenFill, PiStudentBold} from "react-icons/pi";
import {GiDiploma, GiTeacher} from "react-icons/gi";
import UserRoles from "../../../commons/component/header/functionals/models/UserRoles";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import StudentsTeacherRouter from "../components/students/functionals/router/StudentsTeacherRouter";
import StudentsRoot from "../components/students/views/StudentsRoot";
import LivesRoot from "../components/lives/views/LivesRoot";
import LivesRouter from "../components/lives/functionals/router/LivesRouter";
import {MdOutlineLiveTv} from "react-icons/md";
import Dashboard from "../components/dashboard/views/Dashboard";
import AwardsRoot from "../components/awards/views/AwardsRoot";
import AwardsRouter from "../components/awards/functionals/router/AwardsRouter";
import CreateProjectRoot from "../components/projectCreate/views/CreateProjectRoot";
import CreateProjectRouter from "../components/projectCreate/functionals/router/CreateProjectRouter";

export default function DashboardPage() {

    const {user} = useContext(AuthContext);
    const location = useLocation();
    const iconStyling: string = "w-5 h-5 text-accent-main-700 transition duration-75 group-hover:text-accent-main-900";
    const dashboardOptions: { icon: ReactNode, name: string, link: string, role: UserRoles[] }[] = [
        {
            icon: <IoHome className={iconStyling}/>,
            name: 'Panou de bord',
            link: ApplicationRoutes.dashboard,
            role: [UserRoles.ROLE_CHILD, UserRoles.ROLE_STUDENT, UserRoles.ROLE_PARENT, UserRoles.ROLE_TEACHER]
        },
        {
            icon: <GiTeacher className={iconStyling}/>,
            name: 'Cursurile mele',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.courses,
            role: [UserRoles.ROLE_CHILD, UserRoles.ROLE_STUDENT, UserRoles.ROLE_PARENT, UserRoles.ROLE_TEACHER]
        },
        {
            icon: <PiStudentBold className={iconStyling}/>,
            name: 'Studentii mei',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.students,
            role: [UserRoles.ROLE_PARENT, UserRoles.ROLE_TEACHER]
        },
        {
            icon: <MdOutlineLiveTv className={iconStyling}/>,
            name: 'Live-urile mele',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.lives,
            role: [UserRoles.ROLE_CHILD, UserRoles.ROLE_STUDENT, UserRoles.ROLE_PARENT, UserRoles.ROLE_TEACHER]
        },
        {
            icon: <GiDiploma className={iconStyling}/>,
            name: 'Diplomele mele',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.diplomas,
            role: [UserRoles.ROLE_CHILD, UserRoles.ROLE_STUDENT, UserRoles.ROLE_PARENT, UserRoles.ROLE_TEACHER]
        },
        {
            icon: <PiProjectorScreenFill className={iconStyling}/>,
            name: 'Implementare EI',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.projects,
            role: [UserRoles.ROLE_TEACHER]
        }
    ];

    const canSidebarBeDisplayed =
        location.pathname.includes(ApplicationRoutes.dashboard + DashboardUserRouter.courses + "/") ||
        location.pathname.includes(ApplicationRoutes.dashboard + DashboardUserRouter.lives + "/");

    const deleteAccount = () => {

    }

    return (
        <>
            {
                !user?.isActive ?
                    <div className={"w-full px-5 pt-[50px]"}>
                        <div className={"bg-white items-center rounded-lg shadow-lg mt-5 p-5 flex justify-between"}>
                            <h1>Ne pare rau, dar contul tau a fost dezactivat</h1>
                            <button
                                onClick={deleteAccount}
                                className={"text-white bg-error font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit"}
                            >
                                Sterge-ti contul
                            </button>
                        </div>
                    </div> :
                    <div className={"flex gap-2 flex-col md:flex-row md:h-screen overflow-hidden"}>
                        <div className={"fixed bottom-2 left-3 right-3 md:left-0 md:right-0 z-10 md:relative"}>
                            <div
                                className={"w-full md:mx-0 md:h-full rounded-lg md:rounded-none overflow-y-auto items-center bg-white justify-center md:mt-[60px] shadow-lg transition-all " + (canSidebarBeDisplayed ? "md:w-0 p-0" : "md:w-64 p-2 md:p-5")}>
                                <ul className={"justify-center items-center font-medium flex-row md:flex-col " + (canSidebarBeDisplayed ? "hidden" : "flex")}>
                                    {
                                        dashboardOptions.map((option, i) =>
                                            !option.role.includes(user!.role.name) ?
                                                <></> :
                                                <li key={i} className={"w-full"}>
                                                    <Link to={option.link}
                                                          className="flex md:justify-normal justify-center md:items-start items-center p-2 text-accent-main-700 rounded-lg hover:bg-accent-main-100 group">
                                                        {option.icon}
                                                        <span
                                                            className="hidden md:flex ms-3 group-hover:text-accent-main-900">{option.name}</span>
                                                    </Link>
                                                </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className={"flex-1 w-full md:h-full overflow-auto"}>
                            <Routes>
                                <Route path={DashboardUserRouter.root}
                                       element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                                <Route path={DashboardUserRouter.courses + CoursesUserRouter.entrance}
                                       element={<PrivateRoute><CoursesRoot/></PrivateRoute>}/>
                                <Route path={DashboardUserRouter.students + StudentsTeacherRouter.entrance}
                                       element={<PrivateRoute><StudentsRoot/></PrivateRoute>}/>
                                <Route path={DashboardUserRouter.lives + LivesRouter.entrance}
                                       element={<PrivateRoute><LivesRoot/></PrivateRoute>}/>
                                <Route path={DashboardUserRouter.diplomas + AwardsRouter.entrance}
                                       element={<PrivateRoute><AwardsRoot/></PrivateRoute>}/>
                                <Route path={DashboardUserRouter.projects + CreateProjectRouter.entrance}
                                       element={<PrivateRoute><CreateProjectRoot/></PrivateRoute>}/>
                            </Routes>
                        </div>
                    </div>
            }
        </>
    );
}