import React, {FormEvent, useContext, useState} from "react";
import LoginService from "../functionals/service/LoginService";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import LoadingText from "../../../commons/component/loading/views/LoadingText";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";

export default function LoginPage() {

    const {setUser} = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [componentLoading, setComponentLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    function login(event: FormEvent) {
        event.preventDefault();
        setComponentLoading(true);

        new LoginService().login(email, password).then((responseUser) => {
            setUser(responseUser.data);
            const next = searchParams.get("next");
            navigate(next === null ? ApplicationRoutes.dashboard : next);
        }).catch((error) => {
            setError(error.response.data.message);
            setComponentLoading(false);
        }).finally(() => setComponentLoading(false));
    }

    return (
        <div className={"h-screen w-screen flex justify-center items-center bg-background-color"}>
            <div className={"max-w-1/2 w-[400px] p-10 bg-white rounded-xl shadow-lg m-8"}>
                <h1 className={"pb-4 text-3xl text-center"}>Portal logare</h1>
                <form onSubmit={login} className={"flex justify-center items-align flex-col"}>
                    <input type="text" placeholder="Email"
                           required={true}
                           onChange={(e) => setEmail(e.target.value)}
                           className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    <input type="password" placeholder="Password"
                           required={true}
                           onChange={(e) => setPassword(e.target.value)}
                           className="mt-5 mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    <div className={"mb-10 text-error"}>
                        {error ?? ""}
                    </div>
                    <button type="submit"
                            disabled={componentLoading}
                            className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"}>
                        {!componentLoading ? "Login" : <LoadingText color={"#fff"}/>}
                    </button>
                    <Link to={ApplicationRoutes.passwordRecoveryStart} className={"text-center mt-2"}>Ti-ai uitat
                        parola? Reseteaz-o!</Link>
                </form>
            </div>
        </div>
    );
}