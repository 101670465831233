import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import {FaArrowLeftLong} from "react-icons/fa6";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import React, {useEffect, useState} from "react";
import User from "../../../../../../../commons/component/header/functionals/models/User";
import UsersService from "../service/UsersService";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {IoArrowBack, IoArrowForward} from "react-icons/io5";
import UsersAdminRoutes from "../routes/UsersAdminRoutes";
import LivesAdminRouter from "../../../livesAdmin/functionals/router/LivesAdminRouter";
import BackButton from "../../../../../../../commons/component/BackButton";
import DashboardAdminRouter from "../../../../router/DashboardAdminRouter";

export default function ListUsers() {

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [users, setUsers] = useState<User[]>([]);
    const [maximumRecords, setMaximumRecords] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const usersService = new UsersService();
    const location = useLocation();
    const pageSize = 25;

    useEffect(() => {
        if (searchTerm.length !== 0) {
            setIsLoading(true);
            const delayDebounceFn = setTimeout(() => searchUser(searchTerm, 0), 500);
            return () => clearTimeout(delayDebounceFn);
        } else {
            searchUser(searchTerm, null);
        }
    }, [searchTerm, searchParams]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const searchUser = (userName: string, page: number | null) => {
        setIsLoading(true);
        usersService.searchUsers(userName, page ?? getCurrentPage())
            .then((response) => {
                setTotalPages(response.totalPages);
                setMaximumRecords(response.totalElements);
                setUsers(response.content.map((u) => u.data))
            })
            .catch((_) => console.log("error"))
            .finally(() => setIsLoading(false));
    }

    const getCurrentPage = () => {
        let page = parseInt(searchParams.get("page") ?? "0");
        return page < 0 ? 0 : page;
    }


    return (
        <div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Utilizatori"]}/>
            </div>
            <div className="flex flex-col md:flex-row w-full md:justify-between">
                <div
                    className="w-full md:w-fit flex md:justify-center items-center gap-3 px-3 md:px-8 text-xl md:text-4xl font-bold">
                    <BackButton slug={DashboardAdminRouter.users} isFull={false} isSkinny={true}/>
                    <p>Utilizatorii din platforma</p>
                </div>
                <div className="md:w-fit w-full flex gap-2 md:justify-center items-center px-3 md:px-0 md:mr-8">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-lg sm:rounded-lg mt-8 mx-3 md:mx-8">
                {
                    isLoading ?
                        <div className={"p-5"}><LoadingPlaceholder/></div> :
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-accent-main-700 uppercase bg-accent-main-50">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Nume
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Rol
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Detalii
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users.map((u) => {
                                    return <tr
                                        className="odd:bg-white even:bg-gray-50 border-b hover:bg-accent-main-100 hover:text-accent-main-600">
                                        <th scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {u.username}
                                        </th>
                                        <td className="px-6 py-4">
                                            {u.email}
                                        </td>
                                        <td className="px-6 py-4">
                                            {u.role.name.replaceAll("_", " ").replace("ROLE", "")}
                                        </td>
                                        <td className="px-6 py-4">
                                            <Link to={location.pathname + UsersAdminRoutes.viewLeading + u.slug}
                                                  className="font-medium text-accent-main-600 hover:underline">
                                                Mai multe
                                            </Link>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                }
            </div>
            <nav
                className={"items-center flex-column flex-wrap md:flex-row justify-center md:justify-between pt-4 mx-8 " + (searchTerm.length === 0 ? "flex" : "hidden")}
                aria-label="Table navigation">
                <span
                    className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    <span
                        className="font-semibold text-gray-900 dark:text-white">
                        {" " + (getCurrentPage() * pageSize + 1)} - {(getCurrentPage() * pageSize + pageSize) + " "}
                    </span>
                    / Total:
                    <span
                        className="font-semibold text-gray-900 dark:text-white">
                        {" " + maximumRecords + " "}
                    </span>
                </span>
                <ul className="inline-flex -space-x-px text-sm h-8">
                    <li>
                        {
                            (getCurrentPage() - 1) < 0 ?
                                <div
                                    className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-accent-main-300 bg-accent-main-50 border border-accent-main-300 rounded-s-lg">
                                    <IoArrowBack/>
                                </div> :
                                <Link
                                    replace={true}
                                    to={location.pathname + "?page=" + (getCurrentPage() - 1)}
                                    className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-accent-main-500 bg-white border border-accent-main-300 rounded-s-lg hover:bg-accent-main-100 hover:text-accent-main-700">
                                    <IoArrowBack/>
                                </Link>
                        }
                    </li>
                    <li>
                        <div
                            className="flex items-center justify-center px-3 h-8 leading-tight text-accent-main-500 bg-white border border-accent-main-300">
                            Pagina curenta: {(getCurrentPage() + 1)}
                        </div>
                    </li>
                    <li>
                        {
                            (getCurrentPage() + 1) >= totalPages ?
                                <div
                                    className="flex items-center justify-center px-3 h-8 leading-tight text-accent-main-200 bg-accent-main-50 border border-accent-main-300 rounded-e-lg">
                                    <IoArrowForward/>
                                </div> :
                                <Link
                                    replace={true}
                                    to={location.pathname + "?page=" + (getCurrentPage() + 1)}
                                    className="flex items-center justify-center px-3 h-8 leading-tight text-accent-main-500 bg-white border border-accent-main-300 rounded-e-lg hover:bg-accent-main-100 hover:text-accent-main-700">
                                    <IoArrowForward/>
                                </Link>
                        }
                    </li>
                </ul>
            </nav>
        </div>
    );
}