import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import Quiz from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Quiz";

export default class QuizzesService {

    async getQuiz(slug: string): Promise<Quiz> {
        const response: AxiosResponse<Quiz> = await axios.get(apiRoutes.baseQuiz + slug, {withCredentials: true});
        return response.data;
    }
}