import UserRoles from "../../../../../../../../commons/component/header/functionals/models/UserRoles";

export default interface CreateLiveRequest {
    liveTitle: string;
    liveLink: string;
    liveCode: string;
    liveStart: Date;
    liveEnd: Date;
    joinInMinutes: number;
    rolesThatCanSee: UserRoles[];
    description: string;
}

const defaultCreateLiveRequest: CreateLiveRequest = {
    liveTitle: '',
    liveLink: '',
    liveCode: '',
    liveStart: new Date(),
    liveEnd: new Date(),
    joinInMinutes: 0,
    rolesThatCanSee: [],
    description: ''
};

export {defaultCreateLiveRequest};