import CreatedProjects from "../models/CreatedProjects";
import React from "react";
import ProjectStatus from "../models/ProjectStatus";

interface ProjectItemProps {
    project: CreatedProjects;
}

const ProjectItem: React.FC<ProjectItemProps> = ({project}) => {

    const generateStyle = () => {
        return project.projectStatus === ProjectStatus.ACCEPTED ? "text-green-500" : project.projectStatus === ProjectStatus.NONE ? "text-orange-500" : "text-error";
    }

    return (
        <div className={"bg-white w-full shadow-lg rounded-lg flex px-3 py-5"}>
            <div className={"flex justify-between w-full items-center"}>
                <p className={"text-lg font-semibold"}>
                    Proiect creat la data de {new Date(project.createdAt).toLocaleDateString()}
                </p>
                <p className={generateStyle()}>
                    {project.projectStatus === ProjectStatus.ACCEPTED ? "ACCEPTAT" : project.projectStatus === ProjectStatus.NONE ? "IN ASTEPTARE" : "RESPINS"}
                </p>
            </div>
        </div>
    );
}

export default ProjectItem;