import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../commons/environment/EnvironmentRoutes";

export default class VerifyTokenService {

    async verifyToken(token: string): Promise<boolean> {
        const response: AxiosResponse<boolean> = await axios.post<boolean>(apiRoutes.verifyToken.replace("TOKEN", token), {withCredentials: true});
        return response.data;
    }

}