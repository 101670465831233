import React, {FormEvent, useEffect, useState} from "react";
import RegisterService from "../functionals/service/RegisterService";
import LoadingText from "../../../commons/component/loading/views/LoadingText";
import RegisterStudentRequest from "../functionals/models/RegisterStudentRequest";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import {IoIosArrowRoundForward} from "react-icons/io";
import {Link, useNavigate, useParams} from "react-router-dom";


export default function RegisterStudentsPage() {

    const {token} = useParams<{ token: string }>();
    const navigate = useNavigate();
    const [request, setRequest] = useState<RegisterStudentRequest>({
        email: '',
        password: '',
        username: '',
        invitationLink: token,
    });
    const [error, setError] = useState<boolean | null>(null);
    const [componentLoading, setComponentLoading] = useState(false);

    useEffect(() => {
        if (request.invitationLink === undefined)
            navigate(ApplicationRoutes.root);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setRequest((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const register = (event: FormEvent) => {
        event.preventDefault();
        setComponentLoading(true);

        new RegisterService().registerStudent(request).then((_) => {
            setError(false);
            setComponentLoading(false);
        }).catch((_) => {
            setError(true);
            setComponentLoading(false);
        });
    }

    return (
        <div className={"h-screen w-screen flex justify-center items-center bg-background-color"}>
            {
                error === false ?
                    <div className={"text-3xl text-green-700"}>Am trimis un link de verificare pe email</div> :
                    <div className={"max-w-1/2 w-[500px] p-10 bg-white rounded-lg shadow-lg"}>
                        <h1 className={"pb-4 text-3xl text-center"}>Inregistrare elevi</h1>
                        <div className="mb-6">
                            <form onSubmit={register} className={"flex justify-center items-align flex-col"}>
                                <input type="text" placeholder="Email"
                                       required={true}
                                       name="email"
                                       value={request.email}
                                       onChange={handleInputChange}
                                       className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                <input type="password" placeholder="Parola"
                                       required={true}
                                       name="password"
                                       value={request.password}
                                       onChange={handleInputChange}
                                       className="mt-5 mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                <input type="text" placeholder="Numele tau"
                                       required={true}
                                       name="username"
                                       value={request.username}
                                       onChange={handleInputChange}
                                       className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                <div
                                    className={"mb-5 " + error === null || error === true ? "text-error" : "text-black"}>
                                    {
                                        error == null ? "" : error ? "Am intalnit o eroare. Incearca din nou" : "Am trimis un link de verificare pe email"
                                    }
                                </div>
                                <button type="submit"
                                        disabled={componentLoading}
                                        className={"mt-5 text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"}>
                                    {!componentLoading ? "Register" : <LoadingText color={"#fff"}/>}
                                </button>
                                <Link to={ApplicationRoutes.login}
                                      className={"mt-2 flex justify-center items-center w-full text-center"}>
                                    <span className={"mr-1"}>Ai un cont?</span>
                                    <IoIosArrowRoundForward/>
                                </Link>
                            </form>
                        </div>
                    </div>
            }
        </div>
    );
}