export default interface Live {
    slug: string;
    liveTitle: string;
    liveLink: string;
    liveStart: Date;
    liveEnd: Date;
    liveCode: string;
    joinMinutes: number;
    on: boolean;
    description: string;
}

const defaultLive: Live = {
    slug: '',
    liveEnd: new Date(),
    liveLink: '',
    liveStart: new Date(),
    liveTitle: '',
    liveCode: '',
    joinMinutes: 0,
    on: false,
    description: '',
}

export {defaultLive};