import React, {FormEvent, useEffect, useState} from "react";
import RecoveryEmailService from "../functionals/service/RecoveryEmailService";
import LoadingText from "../../../commons/component/loading/views/LoadingText";
import {toast, Toaster} from "react-hot-toast";
import {useNavigate, useParams} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";

export default function PasswordRecoveryEnd() {

    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [componentLoading, setComponentLoading] = useState(false);
    const {token} = useParams<{ token: string }>();
    const navigation = useNavigate();

    useEffect(() => {
        if (!token) return;

        new RecoveryEmailService()
            .checkIfTokenExists(token)
            .catch((_) => navigation(ApplicationRoutes.login, {replace: true}))
    }, [token]);

    function recoveryEmail(event: FormEvent) {
        event.preventDefault();
        if (!token) return;
        setComponentLoading(true);

        if (password !== confirmationPassword) {
            toast.error("Parolele nu sunt aceleasi");
            return;
        }

        new RecoveryEmailService().recoverAccount(token, password).then((responseUser) => {
            toast.success(responseUser.message);
            setPassword("");
            setConfirmationPassword("");
            navigation(ApplicationRoutes.login, {replace: true});
        }).catch((err) => {
            toast.error(err.response.data.message);
        }).finally(() => setComponentLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"h-screen w-screen flex justify-center items-center bg-background-color"}>
                <div className={"max-w-1/2 w-[400px] p-10 bg-white rounded-xl shadow-lg m-8"}>
                    <h1 className={"pb-4 text-3xl text-center"}>Recupereaza-ti contul</h1>
                    <form onSubmit={recoveryEmail} className={"flex justify-center items-align flex-col"}>
                        <input type="password" placeholder="Parola"
                               required={true}
                               onChange={(e) => setPassword(e.target.value)}
                               className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                        <input type="password" placeholder="Reintrodu parola"
                               required={true}
                               onChange={(e) => setConfirmationPassword(e.target.value)}
                               className="my-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                        <button type="submit"
                                disabled={componentLoading}
                                className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"}>
                            {!componentLoading ? "Recupereaza cont" : <LoadingText color={"#fff"}/>}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}