import React, {FormEvent, useEffect, useState} from "react";
import CreateCourseRequest, {defaultCreateCourseRequest} from "../models/requests/CreateCourseRequest";
import {DragDropContext, Draggable, Droppable, DropResult} from "@hello-pangea/dnd";
import {IoIosSave, IoMdAddCircle} from "react-icons/io";
import LessonQuizCard from "../iterables/LessonQuizCard";
import Lesson from "../models/models/Lesson";
import Quiz from "../models/models/Quiz";
import LessonRequest, {defaultLessonRequest} from "../models/requests/LessonRequest";
import QuizzesRequest, {defaultQuizzesRequest} from "../models/requests/QuizzesRequest";
import {IoAdd, IoRemoveCircle} from "react-icons/io5";
import CoursesService from "../service/CoursesService";
import LoadingText from "../../../../../../../commons/component/loading/views/LoadingText";
import {toast, Toaster} from "react-hot-toast";
import QuestionsRequest from "../models/requests/QuiestionsRequest";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import BackButton from "../../../../../../../commons/component/BackButton";
import CoursesAdminRouter from "../router/CoursesAdminRouter";

function instanceOfLesson(object: any): object is Lesson {
    return 'lessonName' in object;
}

export default function CreateCourses() {
    const descriptionBound = 5000;
    const [course, setCourse] = useState<CreateCourseRequest>(defaultCreateCourseRequest);
    const [courseResources, setCourseResources] = useState<(Lesson | Quiz)[]>([]);
    const [lessonRequest, setLessonRequest] = useState<LessonRequest>(defaultLessonRequest);
    const [quizRequest, setQuizRequest] = useState<QuizzesRequest>(defaultQuizzesRequest);
    const [isBuildingLesson, setIsBuildingLesson] = useState<boolean | null>(null);
    const [editedResource, setEditedResource] = useState<string | null>(null);

    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
    const [loadingResourceCreation, setLoadingResourceCreation] = useState<boolean>(false);
    const [courseLoading, setCourseLoading] = useState<boolean>(false);
    const courseService = new CoursesService();

    useEffect(() => {
        setCourse(prevState => ({
            ...prevState, objectsSlugs: courseResources.map(o => {
                return {
                    type: instanceOfLesson(o) ? "lesson" : "quiz",
                    object: o.slug
                };
            })
        }));
    }, [courseResources]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const {name, value} = e.target;
        setCourse((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleInputChangeLessonRequest = (e: React.ChangeEvent<any>) => {
        const {name, value} = e.target;
        setLessonRequest((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleInputChangeQuizRequest = (e: React.ChangeEvent<any>) => {
        const {name, value} = e.target;
        setQuizRequest((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const addQuestion = () => {
        setQuizRequest({
            quizName: quizRequest.quizName,
            questionsRequestList: [...quizRequest.questionsRequestList, {
                question: "",
                response_1: '',
                response_2: '',
                response_3: '',
                response_4: '',
                correctValue: -1
            }]
        });
    };

    const handleInputChangeForQuizQuestions = (index: number, event: React.ChangeEvent<any>) => {
        const {name, value} = event.target;
        const newQuestions = [...quizRequest.questionsRequestList];
        // @ts-ignore
        newQuestions[index][name as keyof QuestionsRequest] = value;
        setQuizRequest(prevState => ({...prevState, questionsRequestListFromInterface: newQuestions}));
    };

    const handleCorrectAnswerChangeForQuizQuestions = (index: number, value: number) => {
        setQuizRequest(prevState => {
            const updatedQuestions = [...prevState.questionsRequestList];
            updatedQuestions[index] = {
                ...updatedQuestions[index],
                correctValue: value
            };
            return {
                ...prevState,
                questionsRequestList: updatedQuestions
            };
        });
    };

    const addLesson = () => {
        setIsBuildingLesson(true);
        setLessonRequest(defaultLessonRequest);
        setEditedResource(null);
    }

    const addQuiz = () => {
        setIsBuildingLesson(false);
        setQuizRequest(defaultQuizzesRequest);
        setEditedResource(null);
    }

    const deleteCourseResources = (slug: string, type: string) => {
        if (type === "lesson") {
            courseService.removeLesson(slug)
                .then((_) => {
                    setCourseResources(courseResources.filter((c) => c.slug !== slug))
                    toast.success("Lectie stearsa cu success")
                })
                .catch((_) => toast.error("Eroare la stergerea lectiei"));
        } else {
            courseService.removeQuiz(slug)
                .then((_) => {
                    setCourseResources(courseResources.filter((c) => c.slug !== slug))
                    toast.success("Quiz stears cu success")
                })
                .catch((_) => toast.error("Eroare la stergerea quiz-ului"));
        }
        setIsBuildingLesson(null);
        setEditedResource(null);
    }

    const createLesson = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (lessonRequest.lessonDescription.length > descriptionBound) {
            toast.error("Descrierea lectiei este foarte lunga");
            return;
        }
        setLoadingResourceCreation(true);
        courseService.createLesson(lessonRequest).then((response) => {
            toast.success("Lectie creata cu success");
            setCourseResources(prev => [...prev, response]);
        }).catch((_) => {
            toast.error("Eroare la crearea lectiei!");
        }).finally(() => {
            setIsBuildingLesson(null);
            setLoadingResourceCreation(false);
        })
    }

    const updateLesson = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (lessonRequest.lessonDescription.length > descriptionBound) {
            toast.error("Descrierea lectiei este foarte lunga");
            return;
        }
        setLoadingResourceCreation(true);
        courseService.updateLesson(lessonRequest, editedResource ?? '').then((response) => {
            toast.success("Lectie editata cu success");
            setCourseResources(prev => prev.map(item => {
                if (item.slug === response.slug) {
                    return response;
                } else {
                    return item;
                }
            }));
        }).catch((_) => {
            toast.error("Eroare la editarea lectiei!");
        }).finally(() => {
            setEditedResource(null);
            setIsBuildingLesson(null);
            setLoadingResourceCreation(false);
        });
    }

    const createQuiz = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (quizRequest.questionsRequestList.length === 0) {
            toast.error("Nu ai setat nicio intrebare");
            return;
        }
        setLoadingResourceCreation(true);
        courseService.createQuiz(quizRequest).then((response) => {
            toast.success("Quiz creat cu success");
            setCourseResources(prev => [...prev, response]);
        }).catch((_) => {
            toast.error("Eroare la crearea quiz-ului");
        }).finally(() => {
            setIsBuildingLesson(null);
            setLoadingResourceCreation(false);
        })
    }

    const updateQuiz = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (quizRequest.questionsRequestList.length === 0) {
            toast.error("Nu ai setat nicio intrebare");
            return;
        }
        setLoadingResourceCreation(true);
        courseService.updateQuiz(quizRequest, editedResource ?? '').then((response) => {
            toast.success("Quiz editat cu success");
            setCourseResources(prev => prev.map(item => {
                if (item.slug === (editedResource ?? '')) {
                    return response;
                } else {
                    return item;
                }
            }));
        }).catch((_) => {
            toast.error("Eroare la editarea quiz-ului!");
        }).finally(() => {
            setEditedResource(null);
            setIsBuildingLesson(null);
            setLoadingResourceCreation(false);
        });
    }

    const saveCourse = () => {
        if (courseResources.length === 0) {
            toast.error("Nu ai nicio lectie sau niciun quiz");
            return;
        }
        if (course.courseImage.length === 0 || course.courseDescription.length === 0 || course.courseName.length === 0) {
            toast.error("Nu ai selectat o imagine, descriere sau nume al cursului");
            return;
        }
        setCourseLoading(true);
        courseService.createCourse(course).then((response) => {
            toast.success("Curs creat cu success");
            setIsLoadingPage(true);
            setTimeout(() => window.history.back(), 2000);
        }).catch((_) => {
            toast.error("Eroare la crearea cursului");
        }).finally(() => {
            setCourseLoading(false);
        });
    }

    const removeQuestion = (index: number): void => {
        setQuizRequest(prevState => ({
            ...prevState,
            questionsRequestList: prevState.questionsRequestList.filter((_, i) => i !== index)
        }));
    };

    const buildNewQuizInterface = () => {
        return <div className={"w-full"}>
            <form onSubmit={editedResource ? updateQuiz : createQuiz} className={"flex flex-col items-center w-full"}>
                <div className={"flex justify-between items-center w-full"}>
                    <h1 className={"text-xl font-bold"}>{editedResource ? "Editeaza un quiz" : "Un nou quiz"}</h1>
                    <button type={"submit"}
                            disabled={loadingResourceCreation}
                            className={"cursor-pointer flex gap-2 justify-center items-center text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit h-fit"}>
                        {loadingResourceCreation ? <LoadingText color={"#fff"}/> :
                            editedResource ? <>Salveaza modificari</> : <><IoAdd/> Salveaza Quiz</>}
                    </button>
                </div>
                <input type="text" placeholder="Numele Quiz-ului"
                       required={true}
                       name={"quizName"}
                       maxLength={100}
                       value={quizRequest.quizName}
                       onChange={handleInputChangeQuizRequest}
                       className="my-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                <hr/>
                <h1 className={"text-xl font-bold mt-4 w-full"}>Intrebarile quiz-ului</h1>
                {quizRequest.questionsRequestList.map((question, index) => (
                    <div key={index} className={"w-full"}>
                        <div className={"flex justify-between"}>
                            <div className={"w-[130px] mt-5 mb-3"}>
                                Intrebarea no. {index + 1}
                            </div>
                            <button type="button" className={"text-error"} onClick={() => removeQuestion(index)}>
                                <IoRemoveCircle/>
                            </button>
                        </div>
                        <input
                            type="text"
                            required={true}
                            placeholder="Enter question"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            name="question"
                            maxLength={100}
                            value={question.question}
                            onChange={(event) => handleInputChangeForQuizQuestions(index, event)}
                        />
                        <div className={"flex lg:flex-row flex-col gap-2"}>
                            <div className={"w-full flex gap-2 mt-5 lg:my-5 items-center"}>
                                <div
                                    onClick={() => handleCorrectAnswerChangeForQuizQuestions(index, 0)}
                                    className="cursor-pointer w-[27px] h-[25px] bg-white rounded-full border border-gray-300 p-0.5">
                                    <div
                                        className={"w-full h-full rounded-full " + (quizRequest.questionsRequestList[index].correctValue === 0 ? "bg-accent-main-500" : "")}></div>
                                </div>
                                <input
                                    type="text"
                                    required={true}
                                    placeholder="Enter reponse 1"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    name="response_1"
                                    maxLength={100}
                                    value={question.response_1}
                                    onChange={(event) => handleInputChangeForQuizQuestions(index, event)}
                                />
                            </div>
                            <div className={"w-full flex gap-2 mt-5 lg:my-5 items-center"}>
                                <div
                                    onClick={() => handleCorrectAnswerChangeForQuizQuestions(index, 1)}
                                    className="cursor-pointer w-[27px] h-[25px] bg-white rounded-full border border-gray-300 p-0.5">
                                    <div
                                        className={"w-full h-full rounded-full " + (quizRequest.questionsRequestList[index].correctValue === 1 ? "bg-accent-main-500" : "")}></div>
                                </div>
                                <input
                                    type="text"
                                    required={true}
                                    placeholder="Enter reponse 2"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    name="response_2"
                                    maxLength={100}
                                    value={question.response_2}
                                    onChange={(event) => handleInputChangeForQuizQuestions(index, event)}
                                />
                            </div>
                            <div className={"w-full flex gap-2 mt-5 lg:my-5 items-center"}>
                                <div
                                    onClick={() => handleCorrectAnswerChangeForQuizQuestions(index, 2)}
                                    className="cursor-pointer w-[27px] h-[25px] bg-white rounded-full border border-gray-300 p-0.5">
                                    <div
                                        className={"w-full h-full rounded-full " + (quizRequest.questionsRequestList[index].correctValue === 2 ? "bg-accent-main-500" : "")}></div>
                                </div>
                                <input
                                    type="text"
                                    required={true}
                                    placeholder="Enter reponse 3"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    name="response_3"
                                    maxLength={100}
                                    value={question.response_3}
                                    onChange={(event) => handleInputChangeForQuizQuestions(index, event)}
                                />
                            </div>
                            <div className={"w-full flex gap-2 mt-5 lg:my-5 items-center"}>
                                <div
                                    onClick={() => handleCorrectAnswerChangeForQuizQuestions(index, 3)}
                                    className="cursor-pointer w-[27px] h-[25px] bg-white rounded-full border border-gray-300 p-0.5">
                                    <div
                                        className={"w-full h-full rounded-full " + (quizRequest.questionsRequestList[index].correctValue === 3 ? "bg-accent-main-500" : "")}></div>
                                </div>
                                <input
                                    type="text"
                                    required={true}
                                    placeholder="Enter reponse 4"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    name="response_4"
                                    maxLength={100}
                                    value={question.response_4}
                                    onChange={(event) => handleInputChangeForQuizQuestions(index, event)}
                                />
                            </div>
                        </div>
                        <hr className={"mt-5"}/>
                    </div>
                ))}
                <button type="button"
                        className={"my-4 cursor-pointer flex gap-2 justify-center items-center text-white bg-accent-main-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit h-fit"}
                        onClick={addQuestion}>Add Question
                </button>
            </form>
        </div>
    }

    const buildNewLessonInterface = () => {
        return <div className={"w-full"}>
            <form onSubmit={editedResource ? updateLesson : createLesson}>
                <div className={"flex justify-between"}>
                    <h1 className={"text-xl font-bold"}>{editedResource ? "Editeaza lectia" : "O noua lectie"}</h1>
                    <button type={"submit"}
                            disabled={loadingResourceCreation}
                            className={"cursor-pointer flex gap-2 justify-center items-center text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit h-fit"}>
                        {loadingResourceCreation ? <LoadingText color={"#fff"}/> :
                            editedResource ? <>Salveaza modificarile</> : <><IoAdd/> Salveaza lectie</>}
                    </button>
                </div>
                <input type="text" placeholder="Numele lectiei"
                       required={true}
                       maxLength={200}
                       name={"lessonName"}
                       value={lessonRequest.lessonName}
                       onChange={handleInputChangeLessonRequest}
                       className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                <div className={"flex gap-4 justify-evenly"}>
                    <input type="text" placeholder="Link catre video"
                           name={"linkToResources"}
                           maxLength={400}
                           required={false}
                           value={lessonRequest.linkToResources ?? ""}
                           onChange={handleInputChangeLessonRequest}
                           className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    <input type="number" placeholder="Durata lectiei"
                           required={true}
                           maxLength={4}
                           name={"lessonTime"}
                           value={lessonRequest.lessonTime}
                           onChange={handleInputChangeLessonRequest}
                           className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                </div>
                <ReactQuill
                    placeholder={"Descrierea lectiei"}
                    className="mt-5 bg-gray-50 w-full"
                    theme="snow" value={lessonRequest.lessonDescription}
                    onChange={v => setLessonRequest((prevData) => ({
                        ...prevData,
                        lessonDescription: v.toString(),
                    }))}/>
            </form>
        </div>
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const items = Array.from(courseResources);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setCourseResources(items);
    };

    const editResource = (resourceSlug: string) => {
        let courseResource = courseResources.filter(cr => cr.slug === resourceSlug)[0];
        if (instanceOfLesson(courseResource)) {
            courseResource = courseResource as Lesson;
            setLessonRequest({
                lessonName: courseResource.lessonName,
                lessonDescription: courseResource.lessonDescription,
                linkToResources: courseResource.linkToResources,
                lessonTime: courseResource.lessonTime
            });
            setIsBuildingLesson(true);
        } else {
            courseResource = courseResource as Quiz;
            setQuizRequest({
                quizName: courseResource.quizTitle,
                questionsRequestList: courseResource.questionsList.map(q => {
                    return {
                        question: q.question,
                        response_1: q.responses[0],
                        response_2: q.responses[1],
                        response_3: q.responses[2],
                        response_4: q.responses[3],
                        correctValue: q.correctResponse ?? -1
                    }
                })
            })
            setIsBuildingLesson(false);
        }
        setEditedResource(resourceSlug);
    }

    return (
        <>
            <div><Toaster/></div>
            {
                isLoadingPage ?
                    <div className={"flex flex-col w-full h-full justify-center items-center"}>
                        <p>Redirecting back</p>
                        <LoadingText color={"#000"}/>
                    </div> :
                    <div className={"px-3 md:px-8 bg-background-color h-full"}>
                        <Breadcrumbs paths={["Cursuri", "Curs nou"]}/>
                        <div className={"flex justify-between"}>
                            <div className="flex justify-center items-center gap-3 text-xl md:text-4xl font-bold">
                                <BackButton slug={CoursesAdminRouter.create} isFull={false} isSkinny={true}/>
                                <p>Creare curs</p>
                            </div>
                            <button disabled={courseLoading} onClick={saveCourse}
                                    className={"cursor-pointer flex gap-2 justify-center items-center mr-3 md:mr-8 text-white bg-accent-main-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit h-fit"}>
                                {
                                    courseLoading ?
                                        <LoadingText color={"#fff"}/> :
                                        <><IoIosSave/> Creaza</>
                                }
                            </button>
                        </div>
                        <div className={"flex flex-col lg:flex-row lg:gap-5 mt-5 h-full"}>
                            <div className={"w-full lg:w-96 flex-col"}>
                                <div
                                    className={"p-5 flex-col text-gray-700 bg-white shadow-md w-full rounded-xl bg-clip-border"}>
                                    <h1 className={"text-xl"}>Panou proprietati curs</h1>
                                    <input type="text" placeholder="Numele cursului"
                                           required={true}
                                           name={"courseName"}
                                           maxLength={150}
                                           onChange={handleInputChange}
                                           className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                    <input type="text" placeholder="Link catre imaginea cursului"
                                           required={true}
                                           maxLength={250}
                                           name={"courseImage"}
                                           onChange={handleInputChange}
                                           className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                    <textarea placeholder="Descrierea cursului"
                                              required={true}
                                              maxLength={500}
                                              name={"courseDescription"}
                                              onChange={handleInputChange}
                                              rows={5}
                                              className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                </div>
                                <div
                                    className={"p-5 my-5 flex-1 flex-col text-gray-700 bg-white shadow-md w-full rounded-xl bg-clip-border"}>
                                    <div className={"flex gap-2 justify-around mb-2"}>
                                        <div onClick={addLesson}
                                             className={"cursor-pointer flex gap-2 justify-center items-center text-white bg-accent-main-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full h-fit"}>
                                            <IoMdAddCircle/> Adauga lectie
                                        </div>
                                        <div onClick={addQuiz}
                                             className={"cursor-pointer flex gap-2 justify-center items-center text-white bg-accent-main-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full h-fit"}>
                                            <IoMdAddCircle/> Adauga quizz
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className={"overflow-y-auto"}>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="courseResources">
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {courseResources.map((object, index) => (
                                                            <Draggable key={object.slug} draggableId={object.slug}
                                                                       index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div>
                                                                            <LessonQuizCard index={object.slug}
                                                                                            object={object}
                                                                                            onElementClick={editResource}
                                                                                            deleteFunction={deleteCourseResources}/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full mb-5"}>
                                <div
                                    className={"w-full p-5 flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border"}>
                                    {
                                        isBuildingLesson == null ?
                                            <div className={"text-center"}>
                                                Creaza din meniul alaturat o lectie sau un quiz
                                            </div> :
                                            isBuildingLesson ?
                                                buildNewLessonInterface() :
                                                buildNewQuizInterface()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}