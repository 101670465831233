import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../commons/environment/EnvironmentRoutes";
import GeneralResponse from "../../../../commons/environment/models/GeneralResponse";

export default class RecoveryEmailService {

    async checkAndRecoverEmail(email: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post<GeneralResponse<string>>(apiRoutes.checkAndRecoverEmail, {
            email: email
        }, {withCredentials: true});
        return response.data;
    }

    async recoverAccount(token: string, password: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post<GeneralResponse<string>>(apiRoutes.recoverAccount, {
            password: password,
            token: token
        }, {withCredentials: true});
        return response.data;
    }

    async checkIfTokenExists(token: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.get<GeneralResponse<string>>(apiRoutes.checkToken + token, {withCredentials: true});
        return response.data;
    }

}