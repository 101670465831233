import React from "react";
import {Route, Routes} from "react-router-dom";
import LivesRouter from "../functionals/router/LivesRouter";
import ListLives from "../functionals/components/ListLives";
import PrivateRoute from "../../../../../commons/functionals/authLogic/PrivateRoute";
import ViewLive from "../functionals/components/ViewLive";

export default function LivesRoot() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={LivesRouter.root} element={<PrivateRoute><ListLives/></PrivateRoute>}/>
                <Route path={LivesRouter.view} element={<PrivateRoute><ViewLive/></PrivateRoute>}/>
            </Routes>
        </div>
    )
}