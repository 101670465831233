import Course from "../models/models/Course";
import React, {useState} from "react";
import {FaArrowRightLong} from "react-icons/fa6";
import {Link, useLocation} from "react-router-dom";
import CoursesAdminRouter from "../router/CoursesAdminRouter";
import {AiFillDelete} from "react-icons/ai";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";

interface CourseCardProps {
    course: Course;
    deleteCourse: (slug: string) => void;
}

const CourseCard: React.FC<CourseCardProps> = ({course, deleteCourse}) => {

    const location = useLocation();
    const [loading, setLoading] = useState<boolean>();

    return (
        <div className={"w-full max-w-[400px] p-5 transition-all " + (loading ? "h-[150px]" : "")}>
            {
                loading ?
                    <div
                        className="bg-white border border-gray-200 rounded-lg shadow py-2 flex justify-center items-center">
                        <LoadingPlaceholder/>
                    </div> :
                    <div
                        className="bg-white border border-gray-200 rounded-lg shadow">
                        <Link to={location.pathname + CoursesAdminRouter.viewLeading + course.slug}>
                            <div className="rounded-t-lg h-[250px] relative overflow-hidden">
                                <img className="absolute inset-0 w-full h-full object-cover" src={course.courseImage}
                                     alt=""/>
                            </div>
                        </Link>
                        <div className="p-5">
                            <Link to={location.pathname + CoursesAdminRouter.viewLeading + course.slug}>
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    {
                                        course.courseName
                                    }
                                </h5>
                            </Link>
                            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                {
                                    course.courseDescription
                                }
                            </p>
                            <div className={"flex flex-row justify-between"}>
                                <Link to={location.pathname + CoursesAdminRouter.viewLeading + course.slug}
                                      className="inline-flex gap-2 hover:gap-3 transition-all items-center px-3 py-2 text-sm font-medium text-center border border-accent-main-200 text-accent-main-600 hover:text-white hover:bg-accent-main-500 rounded-lg">
                                    Mai multe
                                    <FaArrowRightLong/>
                                </Link>
                                <button
                                    onClick={() => {
                                        setLoading(true);
                                        deleteCourse(course.slug);
                                    }}
                                    className="inline-flex gap-2 items-center px-3 py-2 text-sm font-medium text-center border border-red-200 text-red-600 hover:text-white hover:bg-red-500 rounded-lg">
                                    <AiFillDelete/>
                                    Sterge cursul
                                </button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default CourseCard;