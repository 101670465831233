import React from "react";
import ReactLoading from "react-loading";
import LoadingInterface from "../functionals/models/LoadingInterface";

const LoadingText: React.FC<LoadingInterface> = ({color}) => {
    return (
        <div className={"flex justify-center items-center"}>
            <ReactLoading color={color} width={20} height={20}/>
        </div>
    );
}

export default LoadingText;