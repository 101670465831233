import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LivesAdminRouter from "../router/LivesAdminRouter";
import {IoIosAddCircleOutline} from "react-icons/io";
import LivesService from "../service/LivesService";
import Live from "../models/models/Live";
import LiveCard from "../iterables/LiveCard";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import {toast, Toaster} from "react-hot-toast";
import BackButton from "../../../../../../../commons/component/BackButton";
import DashboardAdminRouter from "../../../../router/DashboardAdminRouter";

export default function ListLives() {
    const location = useLocation();
    const livesService = new LivesService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [lives, setLives] = useState<Live[]>([]);
    const [livesSlugEnded, setLivesSlugEnded] = useState<string[]>([]);

    useEffect(() => {
        setIsLoading(true);
        livesService.listLives().then((response) => {
            setLives(response);
        })
            .catch((_) => setError(true))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date();
            const updatedLives = lives.map(live => {
                if (livesSlugEnded.includes(live.slug))
                    return live;

                if (new Date(live.liveStart).getTime() <= currentTime.getTime() && currentTime.getTime() <= new Date(live.liveEnd).getTime()) {
                    return {...live, on: true};
                }
                return live;
            });
            setLives(updatedLives);
        }, 1000);

        return () => clearInterval(interval);
    }, [lives]);

    const closeLive = (slug: string) => {
        livesService.stopLive(slug).then((response) => {
            toast.success(response.message);
            setLivesSlugEnded([...livesSlugEnded, slug]);
            setLives(prevLives => prevLives.map(live => {
                if (live.slug === slug) {
                    return {...live, on: false};
                } else {
                    return live;
                }
            }));
        })
            .catch((_) => setError(true))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Live-uri"]}/>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between px-3 md:px-0">
                <div className="flex md:justify-center items-center gap-3 px-3 md:px-8 text-xl md:text-4xl font-bold">
                    <BackButton slug={DashboardAdminRouter.lives} isFull={false} isSkinny={true}/>
                    <p>Live-urile programate din platforma</p>
                </div>
                <Link
                    to={location.pathname + LivesAdminRouter.create}
                    className="w-full md:w-32 mr-0 md:mr-8 flex gap-2 justify-center items-center text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    <IoIosAddCircleOutline className={"text-lg"}/> Creaza
                </Link>
            </div>
            <div className="flex flex-col px-3 md:px-8 mt-3 gap-5">
                {
                    isLoading ?
                        <div className={"mt-8 w-full justify-center"}><LoadingPlaceholder/></div> :
                        lives.map((l, index) => <LiveCard key={index} live={l} action={closeLive}/>)
                }
            </div>
        </>
    );
}