import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import CreatedProjects from "../models/CreatedProjects";
import {toast} from "react-hot-toast";

export default class CreateProjectService {
    async getMyProjects(): Promise<CreatedProjects[]> {
        const response: AxiosResponse<CreatedProjects[]> = await axios.get<CreatedProjects[]>(apiRoutes.myProjects, {withCredentials: true});
        return response.data;
    }

    async createProject(file: File | null, resourceLink: string | null): Promise<CreatedProjects> {
        const formData = new FormData();

        if (file !== null) {
            formData.append('file', file);
        } else if (resourceLink !== null) {
            formData.append('link', resourceLink);
        } else {
            toast.error("Ataseaza o resursa pentru a inscrie un proiect");
        }

        const response: AxiosResponse<CreatedProjects> = await axios.post<CreatedProjects>(apiRoutes.rootProjects, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
            withCredentials: true,
        });
        return response.data;
    }
}