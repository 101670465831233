import React from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../../../../../commons/functionals/authLogic/PrivateRoute";
import AwardsRouter from "../functionals/router/AwardsRouter";
import ListAwards from "../functionals/components/ListAwards";

export default function AwardsRoot() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={AwardsRouter.root} element={<PrivateRoute><ListAwards/></PrivateRoute>}/>
            </Routes>
        </div>
    )
}