import React from 'react';
import {IoCheckmarkCircle} from 'react-icons/io5';

interface PermissionListProps {
    groupName: string;
    permissions: string[];
}

const PermissionList: React.FC<PermissionListProps> = ({groupName, permissions}) => {
    return (
        <div className="border p-4 rounded shadow-lg my-5">
            <h2 className="text-lg font-semibold mb-2">
                Grupul
                <span
                    className={"mx-2 px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg "}>
                    {groupName}
                </span>
                :
            </h2>
            <ul>
                {permissions.map((permission, i) => (
                    <li
                        key={i}
                        className="inline-flex gap-1 items-center m-1 text-base text-accent-main-800 bg-accent-main-100 border-accent-main-200 border px-2 rounded-md">
                        <IoCheckmarkCircle className={"text-green-500"}/> {permission}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PermissionList;
