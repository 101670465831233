import React, {useState} from "react";
import {IoCheckmark, IoChevronDown, IoChevronUp, IoCloseSharp} from "react-icons/io5";
import CompleteProject from "../models/CompleteProject";
import LoadingText from "../../../../../../../commons/component/loading/views/LoadingText";
import {Link} from "react-router-dom";

interface ProjectItemProps {
    project: CompleteProject;
    accept: (slug: string) => void;
    reject: (slug: string) => void;
    isLoading: boolean;
}

const ProjectItem: React.FC<ProjectItemProps> = ({project, accept, reject, isLoading}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="bg-white w-full shadow-lg rounded-lg flex flex-col px-3 py-5">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <p className="text-lg font-semibold">
                    Proiect creat de {project.email} la data de {new Date(project.createdAt).toLocaleDateString()}
                </p>

                <button onClick={toggleOpen} className="flex items-center">
                    <span className="font-semibold">Detalii</span>
                    {isOpen ? <IoChevronUp className="ml-2"/> : <IoChevronDown className="ml-2"/>}
                </button>
            </div>
            {isOpen && (
                <div className="mt-5">
                    {
                        project.imagePath === null ?
                            <Link to={project.resourcesPath ?? ""}
                                  className={"cursor-pointer underline text-accent-main-500 hover:text-accent-main-600"}>
                                Acceseaza: {project.resourcesPath}
                            </Link> :
                            <img src={project.imagePath ?? ""} alt="Nu ai access sa vezi aceasta imagine"
                                 className="w-full h-auto rounded-lg"/>
                    }
                    {
                        isLoading ?
                            <LoadingText color={"#000000"}/> :
                            <div className="flex flex-row gap-2 mt-3">
                                <button
                                    className="w-full bg-green-500 p-2 rounded-lg font-bold flex justify-center items-center"
                                    onClick={() => accept(project.slug)}>
                                    <IoCheckmark className="text-white text-lg"/>
                                </button>
                                <button
                                    className="w-full bg-error p-2 rounded-lg font-bold flex justify-center items-center"
                                    onClick={() => reject(project.slug)}>
                                    <IoCloseSharp className="text-white text-lg"/>
                                </button>
                            </div>
                    }
                </div>
            )
            }
        </div>
    )
        ;
}

export default ProjectItem;
