import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../commons/environment/EnvironmentRoutes";
import UserResponse from "../../../../commons/component/header/functionals/models/UserResponse";

export default class LoginService {

    async login(email: String, password: String): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axios.post<UserResponse>(apiRoutes.login, {
            email: email,
            password: password
        }, {withCredentials: true});
        return response.data;
    }

}