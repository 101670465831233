import React from "react";
import {Route, Routes} from "react-router-dom";
import CoursesUserRouter from "../functionals/router/CoursesUserRouter";
import ListCourses from "../functionals/components/ListCourses";
import ViewCourse from "../functionals/components/ViewCourse";
import PrivateRoute from "../../../../../commons/functionals/authLogic/PrivateRoute";

export default function CoursesRoot() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={CoursesUserRouter.root} element={<PrivateRoute><ListCourses/></PrivateRoute>}/>
                <Route path={CoursesUserRouter.view} element={<PrivateRoute><ViewCourse/></PrivateRoute>}/>
            </Routes>
        </div>
    )
}