import React from "react";

interface SplittedTextProps {
    text: string
}

const SplittedText: React.FC<SplittedTextProps> = ({text}) => {
    return (
        <div className="flex items-center">
            <div className="flex-grow border-t border-gray-300"></div>
            <div className="px-4 py-5 text-gray-400">{text}</div>
            <div className="flex-grow border-t border-gray-300"></div>
        </div>
    );
}

export default SplittedText;