import axios from "axios";
import apiRoutes from "../../../../commons/environment/EnvironmentRoutes";
import RegisterStudentRequest from "../models/RegisterStudentRequest";
import RegisterProfessorRequest from "../models/RegisterProfessorRequest";
import RegisterParentRequest from "../models/RegisterParentRequest";

export default class RegisterService {

    async registerStudent(request: RegisterStudentRequest): Promise<Response> {
        return await axios.post(apiRoutes.registerStudent, request, {withCredentials: true});
    }

    async registerProfessor(request: RegisterProfessorRequest): Promise<Response> {
        return await axios.post(apiRoutes.registerTeacher, request, {withCredentials: true});
    }

    async registerParent(request: RegisterParentRequest): Promise<Response> {
        return await axios.post(apiRoutes.registerParent, request, {withCredentials: true});
    }

}