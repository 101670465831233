import React, {useState} from "react";
import {IoIosAddCircleOutline} from "react-icons/io";
import LivesService from "../service/LivesService";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import {toast, Toaster} from "react-hot-toast";
import CreateLiveRequest, {defaultCreateLiveRequest} from "../models/requests/CreateLiveRequest";
import UserRoles from "../../../../../../../commons/component/header/functionals/models/UserRoles";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import BackButton from "../../../../../../../commons/component/BackButton";
import LivesAdminRouter from "../router/LivesAdminRouter";

export default function CreateLives() {
    const livesService = new LivesService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [live, setLive] = useState<CreateLiveRequest>(defaultCreateLiveRequest);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const {name, value} = e.target;
        setLive((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUserRoleChange = (input: UserRoles) => {
        if (live.rolesThatCanSee.includes(input)) {
            setLive((prevData) => ({
                ...prevData,
                rolesThatCanSee: live.rolesThatCanSee.filter(selectedRole => selectedRole !== input),
            }));
        } else {
            setLive((prevData) => ({
                ...prevData,
                rolesThatCanSee: [...live.rolesThatCanSee, input]
            }));
        }

    }

    const createLive = () => {
        setIsLoading(true);
        livesService.createLive(live)
            .then((_) => {
                setLive(defaultCreateLiveRequest);
                toast.success("Live creat cu success");
            })
            .catch((_) => toast.error("Nu am putut crea live-ul"))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Live-uri", "Live nou"]}/>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between px-3 md:px-0">
                <div className="flex md:justify-center items-center gap-3 px-3 md:px-8 text-xl md:text-4xl font-bold">
                    <BackButton slug={LivesAdminRouter.create} isFull={false} isSkinny={true}/>
                    <p>Creaza un live nou</p>
                </div>
                <button
                    onClick={createLive}
                    disabled={isLoading}
                    className="w-full md:w-32 mr-0 md:mr-8 flex gap-2 justify-center items-center text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    <IoIosAddCircleOutline className={"text-lg"}/> Creaza
                </button>
            </div>
            <div className={"flex flex-col lg:flex-row gap-3 lg:gap-5 mt-5 mx-3 md:mx-8 h-full"}>
                <div className={"w-full lg:w-96 flex-col"}>
                    <div
                        className={"p-5 flex-col text-gray-700 bg-white shadow-md w-full rounded-xl bg-clip-border"}>
                        <h1 className={"text-xl"}>Panou proprietati live</h1>
                        <input type="text" placeholder="Numele live-ului"
                               required={true}
                               name={"liveTitle"}
                               maxLength={150}
                               onChange={handleInputChange}
                               className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                        <input type="text" placeholder="Codul live-ului"
                               required={true}
                               maxLength={250}
                               name={"liveCode"}
                               onChange={handleInputChange}
                               className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                        <input placeholder="Durata (in minute) de intrare in live"
                               required={true}
                               type={"number"}
                               maxLength={3}
                               name={"joinInMinutes"}
                               onChange={handleInputChange}
                               className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                        <h1 className={"text-lg mt-5"}>Cine poate vedea acest live:</h1>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-3">
                            {
                                Object.values(UserRoles).filter(role => role !== UserRoles.ROLE_SUPER_ADMIN && role !== UserRoles.none).map((role, i) =>
                                    <div
                                        key={i}
                                        className={"w-full border rounded cursor-pointer " + (live.rolesThatCanSee.includes(role) ? "border-accent-main-500 bg-white" : "border-gray-300 bg-gray-50")}
                                        onClick={() => handleUserRoleChange(role)}
                                    >
                                        <div
                                            className={"w-full text-center py-4 " + (live.rolesThatCanSee.includes(role) ? "text-accent-main-500" : "text-gray-400")}>
                                            {role.replace("ROLE_", "")}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className={"w-full mb-5"}>
                    <div
                        className={"w-full flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border p-5"}>
                        <p>Descriere:</p>
                        <ReactQuill value={live.description} theme="snow" onChange={v => setLive((prevData) => ({
                            ...prevData,
                            description: v.toString(),
                        }))}/>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 my-3">
                            <div className={"flex flex-col"}>
                                <p>Live Start</p>
                                <DateTimePicker disableClock={true} onChange={(value) => setLive((prevData) => ({
                                    ...prevData,
                                    liveStart: new Date(value ?? new Date())
                                }))} value={live.liveStart}/>
                            </div>
                            <div className={"flex flex-col"}>
                                <p>Live End</p>
                                <DateTimePicker disableClock={true} onChange={(value) => setLive((prevData) => ({
                                    ...prevData,
                                    liveEnd: new Date(value ?? new Date())
                                }))} value={live.liveEnd}/>
                            </div>
                        </div>
                        <input type="text" placeholder="Linkul live-ului"
                               required={true}
                               maxLength={500}
                               name={"liveLink"}
                               onChange={handleInputChange}
                               className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                        <div
                            className={"w-full h-[600px] rounded-lg bg-black flex justify-center items-center flex-col"}>
                            {live.liveLink.length !== 0 && <iframe title={live.liveTitle} src={live.liveLink}
                                                                   className={"w-full h-full rounded-lg"}/>}
                            {live.liveLink.length === 0 && <div className={"relative"}><LoadingPlaceholder/></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}