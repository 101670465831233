import React, {useEffect, useState} from "react";
import UserCoursesService from "../service/UserCoursesService";
import CourseCard from "../iterables/CourseCard";
import Breadcrumbs from "../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";
import Course from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Course";

export default function ListCourses() {
    const userCoursesService = new UserCoursesService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [courses, setCourses] = useState<Course[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setIsLoading(true)
        const delayDebounceFn = setTimeout(() => search(searchTerm), 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setSearchTerm(event.target.value);
    };

    const search = (term: string) => {
        userCoursesService.listMyCourses(term).then((response) => setCourses(response)).finally(() => setIsLoading(false));
    }

    return (
        <>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Cursuri"]}/>
            </div>
            <div className="flex flex-col md:flex-row justify-between">
                <div>
                    <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Acestea sunt cursurile tale:</h1>
                </div>
                <div className="mx-3 mt-3 md:mr-8">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="px-3 py-2 w-full md:w-fit border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                </div>
            </div>
            <div className="flex flex-wrap gap-5 md:mb-0 mb-14">
                {
                    isLoading ?
                        <div className={"mt-8 justify-center w-full"}><LoadingPlaceholder/></div> :
                        courses.length === 0 ? <p>Nu ai niciun curs alocat</p> :
                            courses.map((c, index) => <CourseCard key={index} course={c}/>)
                }
            </div>
        </>
    );
}