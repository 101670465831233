import User from "../../../../../../../commons/component/header/functionals/models/User";
import UserRoles from "../../../../../../../commons/component/header/functionals/models/UserRoles";

export default interface ComplexUser extends User {
    city: string | null;
    school: string | null;
    addressing: string | null;
    phone: string | null;
    country: string | null;
    invitationLink: string;
    courses: {
        slug: string;
        name: string;
        image: string;
    }[],
    students: User[]
}

const defaultUser: ComplexUser = {
    slug: "",
    username: "",
    email: "",
    role: {name: UserRoles.ROLE_STUDENT},
    courses: [],
    students: [],
    phone: "",
    country: "Fara tara",
    addressing: "",
    school: "",
    city: "",
    invitationLink: "",
    isActive: true,
    isVerified: true
};

export {defaultUser}