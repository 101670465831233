import React from "react";
import Lesson from "../models/models/Lesson";
import {FaBook} from "react-icons/fa";

interface ViewLessonCardProps {
    lesson: Lesson;
}

const ViewLessonCard: React.FC<ViewLessonCardProps> = ({lesson}) => {
    return (
        <li className="mb-10 ms-6">
            <span
                className="absolute flex items-center justify-center w-6 h-6 bg-accent-main-100 rounded-full -start-3 ring-8 ring-white">
                <FaBook className={"text-xs text-gray-950"}/>
            </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                {lesson.lessonName}
                <span
                    className="bg-accent-main-100 text-accent-main-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded ms-3">
                    Lectie
                </span>
            </h3>
            <time
                className="block mb-2 text-sm font-normal leading-none text-gray-400">
                Timpul lectiei: {lesson.lessonTime} minute
            </time>
            <p className="mb-4 text-base font-normal text-gray-500"
               dangerouslySetInnerHTML={{__html: lesson.lessonDescription}}>
            </p>
            {
                lesson.linkToResources !== null &&
                <iframe title={lesson.slug} src={lesson.linkToResources} allow={"fullscreen picture-in-picture"}
                        className={"w-[200px] h-[120px] rounded-lg"}/>
            }
        </li>
    );
}

export default ViewLessonCard;