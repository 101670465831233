import Live from "../models/models/Live";
import React from "react";
import moment from "moment";
import {FaStop} from "react-icons/fa";

interface CourseCardProps {
    live: Live;
    action: (slug: string) => void
}

const LiveCard: React.FC<CourseCardProps> = ({live, action}) => {
    return (
        <div
            className={"w-full h-fit p-5 bg-white rounded-lg shadow flex sm:flex-row flex-col gap-2 items-center justify-between " + (live.on ? "border-2 border-error" : "border border-gray-200")}>
            <div>
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {
                        live.liveTitle
                    }
                </h5>
                <p className="font-normal text-sm text-gray-700 dark:text-gray-400">
                    {
                        moment(live.liveStart).format('DD MMMM YYYY HH:mm') + " - " + moment(live.liveEnd).format('HH:mm')
                    }
                </p>
            </div>
            <div>
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Durata de intrare:
                </h5>
                <p className="font-normal text-sm text-gray-700 dark:text-gray-400">
                    {
                        live.joinMinutes
                    }
                </p>
            </div>

            <div>
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Cod Live:
                </h5>
                <p className="font-normal text-sm text-gray-700 dark:text-gray-400">
                    {
                        live.liveCode
                    }
                </p>
            </div>
            {
                live.on ?
                    <div
                        onClick={(_) => action(live.slug)}
                        className="cursor-pointer inline-flex gap-2 transition-all items-center px-3 py-2 text-sm font-medium text-center border border-error-200 text-error hover:text-white hover:bg-error rounded-lg">
                        <FaStop/>
                        Stop Live
                    </div>
                    :
                    <div className={"w-[109px]"}></div>
            }
        </div>
    );
}

export default LiveCard;