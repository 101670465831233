import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import LoadingText from "../../../commons/component/loading/views/LoadingText";
import VerifyTokenService from "../functionals/service/VerifyTokenService";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";

export default function VerifyTokenPage() {

    const {token} = useParams<{ token: string }>();
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!token) return;

        new VerifyTokenService().verifyToken(token!).then((response) => {
            setValidated(response);
            setLoading(false);
        }).catch((_) => {
            setLoading(false);
        })
    }, []);

    return (
        <div className={"h-screen w-screen flex justify-center items-center"}>
            {
                loading ?
                    <div>
                        <LoadingText color={"#fff"}/>
                        <p>Iti verificam emailul!</p>
                    </div>
                    :
                    <div>
                        <p>
                            {
                                validated ?
                                    <div
                                        className="p-5 flex flex-col items-center justify-center bg-white rounded-xl shadow-lg">
                                        <p className={"text-xl mb-5"}>Emailul validat cu success!</p>
                                        <Link to={ApplicationRoutes.login}
                                              className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"}>
                                            Catre logare
                                        </Link>
                                    </div> :
                                    "Exista o eroare, incearca din nou!"
                            }
                        </p>
                    </div>
            }
        </div>
    );
}