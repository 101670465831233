import React from "react";
import {IoTrashBin} from "react-icons/io5";
import Lesson from "../models/models/Lesson";
import Quiz from "../models/models/Quiz";

interface LessonQuizCardProps {
    object: Lesson | Quiz;
    index: any;
    deleteFunction: (slug: string, type: string) => void
    onElementClick: (slug: string) => void
}

function isLesson(object: any): object is Lesson {
    return 'lessonName' in object;
}

const LessonQuizCard: React.FC<LessonQuizCardProps> = ({index, object, deleteFunction, onElementClick}) => {

    return (
        <div key={index} role={"button"}
             className="flex my-2 justify-between items-center w-full py-2 pl-4 pr-1 leading-tight hover:bg-blue-50 hover:text-blue-gray-900 transition-all rounded-lg outline-none text-start">
            <div className={"flex flex-col w-full"} onClick={() => onElementClick(object.slug)}>
                <p className={"text-xs text-gray-400"}>
                    {isLesson(object) ? "Lectie" : "Quiz"}
                </p>
                <p>
                    {isLesson(object) ? (object as Lesson).lessonName : (object as Quiz).quizTitle}
                </p>
            </div>
            <button
                className="cursor-pointer flex justify-center items-center text-white bg-error relative h-8 max-h-[40px] w-8 max-w-[40px] select-none rounded-xl text-center align-middle font-sans text-xs font-medium uppercase transition-all"
                type="button"
                onClick={() => deleteFunction(object.slug, isLesson(object) ? "lesson" : "quiz")}>
                <IoTrashBin/>
            </button>
        </div>
    );
}

export default LessonQuizCard;