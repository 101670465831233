import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import GeneralResponse from "../../../../../../commons/environment/models/GeneralResponse";
import ComplexUser
    from "../../../../../dashboardAdmin/functionals/components/usersAdmin/functionals/models/ComplexUser";
import Course from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Course";

export default class StudentsService {

    async getMyStudents(teacherSlug: string): Promise<ComplexUser> {
        const response: AxiosResponse<GeneralResponse<ComplexUser>> = await axios.get(apiRoutes.teacherDetails + teacherSlug, {withCredentials: true});
        return response.data.data;
    }

    async getMyStudentsCourses(teacherSlug: string): Promise<Course[]> {
        const response: AxiosResponse<Course[]> = await axios.get(apiRoutes.teacherStudentCourses.replace("SLUG", teacherSlug), {withCredentials: true});
        return response.data;
    }

    async toggleUserActive(studentSlug: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post(apiRoutes.toggleActivation.replace("SLUG", studentSlug), {}, {withCredentials: true});
        return response.data;
    }
}