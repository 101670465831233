import React from "react";
import {FaBook} from "react-icons/fa";
import Quiz from "../models/models/Quiz";
import ViewQuestionCard from "./ViewQuestionCard";

interface ViewQuizCardProps {
    quiz: Quiz;
}

const ViewQuizCard: React.FC<ViewQuizCardProps> = ({quiz}) => {
    return (
        <li key={quiz.slug} className="mb-10 ms-6">
            <span
                className="absolute flex items-center justify-center w-6 h-6 bg-accent-main-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <FaBook className={"text-xs"}/>
            </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-950 dark:text-white">
                {quiz.quizTitle}
                <span
                    className="bg-accent-main-100 text-accent-main-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">
                    Quiz
                </span>
            </h3>
            <ol className="relative border-s border-gray-200 dark:border-gray-700">
                {
                    quiz.questionsList.map((q, i) => <ViewQuestionCard question={q} index={i}/>)
                }
            </ol>
        </li>
    );
}

export default ViewQuizCard;