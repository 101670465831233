import React, {useContext, useEffect, useState} from "react";
import LivesService from "../service/LivesService";
import Breadcrumbs from "../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";
import {AuthContext} from "../../../../../../commons/functionals/authLogic/AuthContext";
import {Toaster} from "react-hot-toast";
import Live from "../../../../../dashboardAdmin/functionals/components/livesAdmin/functionals/models/models/Live";
import LiveCard from "../iterables/LiveCard";

export default function ListLives() {
    const {user} = useContext(AuthContext);
    const studentsService = new LivesService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lives, setLives] = useState<Live[]>([]);

    useEffect(() => {
        setIsLoading(true)
        studentsService.getMyLives()
            .then((response) => {
                setLives(response);
            })
            .catch((_) => console.log())
            .finally(() => setIsLoading(false));
    }, [user]);


    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date();
            const updatedLives = lives.map(live => {
                if (new Date(live.liveStart).getTime() <= currentTime.getTime() && currentTime.getTime() <= new Date(live.liveEnd).getTime()) {
                    return {...live, on: true};
                }
                return live;
            });
            setLives(updatedLives);
        }, 1000);

        return () => clearInterval(interval);
    }, [lives]);

    return (
        <>
            <div><Toaster/></div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Live-uri"]}/>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between">
                <div>
                    <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Live-urile programate:</h1>
                </div>
            </div>
            <div className="flex flex-wrap gap-5 mt-8 mx-3 md:mx-8">
                {
                    isLoading ?
                        <div className={"mt-8 justify-center w-full"}><LoadingPlaceholder/></div> :
                        lives.length === 0 ?
                            <p>Nu este niciun live programat</p> :
                            <ul className={"w-full flex flex-col gap-2"}>
                                {
                                    lives.map((u, index) => <LiveCard live={u}/>)
                                }
                            </ul>
                }
            </div>
        </>
    );
}