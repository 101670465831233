import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../../commons/environment/EnvironmentRoutes";
import UserRoles from "../../../../../../../commons/component/header/functionals/models/UserRoles";

export default class PermissionsService {

    async getPermissions(permission: UserRoles): Promise<string[]> {
        const response: AxiosResponse<string[]> = await axios.get(apiRoutes.permissionsAll + `/${permission}`, {withCredentials: true});
        return response.data;
    }
}