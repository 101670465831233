import React, {useContext, useEffect, useState} from "react";
import StudentsService from "../service/StudentsService";
import Breadcrumbs from "../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";
import User from "../../../../../../commons/component/header/functionals/models/User";
import ViewUserCard from "../../../courses/functionals/iterables/ViewUserCard";
import {AuthContext} from "../../../../../../commons/functionals/authLogic/AuthContext";
import ApplicationRoutes from "../../../../../../commons/environment/AppRoutingName";
import {toast, Toaster} from "react-hot-toast";
import Course from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Course";
import ViewCourseCard
    from "../../../../../dashboardAdmin/functionals/components/usersAdmin/functionals/iterables/ViewCourseCard";
import UserRoles from "../../../../../../commons/component/header/functionals/models/UserRoles";
import {useNavigate} from "react-router-dom";

export default function ListStudents() {
    const {user} = useContext(AuthContext);
    const studentsService = new StudentsService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingStudentCourses, setIsLoadingStudentCourses] = useState<boolean>(false);
    const [students, setStudents] = useState<User[]>([]);
    const [courses, setCourses] = useState<Course[]>([]);
    const [invitationLink, setInvitationLink] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchedStudents, setSearchedStudents] = useState<User[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.role.name === UserRoles.ROLE_CHILD || user?.role.name === UserRoles.ROLE_STUDENT) {
            navigate(ApplicationRoutes.dashboard);
            return;
        }
        setIsLoading(true)
        setIsLoadingStudentCourses(true);
        studentsService.getMyStudents(user?.slug ?? '')
            .then((response) => {
                setInvitationLink(response.invitationLink);
                setStudents(response.students);
            })
            .catch((_) => console.log())
            .finally(() => setIsLoading(false));

        studentsService.getMyStudentsCourses(user?.slug ?? '')
            .then((response) => {
                setCourses(response);
            })
            .catch((_) => console.log())
            .finally(() => setIsLoadingStudentCourses(false));
    }, [user]);

    useEffect(() => {
        setSearchedStudents(students.filter(student => student.username.includes(searchTerm)));
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const deleteStudent = (slug: string) => {
        setStudents(prevStudents =>
            prevStudents.map(student =>
                student.slug === slug ? {...student, isActive: !student.isActive} : student
            )
        );
        studentsService.toggleUserActive(slug)
            .then(response => toast.success(response.message))
            .catch((error) => {
                setStudents(prevStudents =>
                    prevStudents.map(student =>
                        student.slug === slug ? {...student, isActive: !student.isActive} : student
                    )
                );
                toast.error(error.response.data.message ?? "Am intampinat o eroare");
            });
    }

    const handleClick = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_BASE_LINK + ApplicationRoutes.registerStudentsEntry + invitationLink)
        toast.success("Link copiat!");
    };

    return (
        <>
            <div><Toaster/></div>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Studenti"]}/>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between">
                <div>
                    <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Acestia sunt studentii tai:</h1>
                </div>
                <div className="mx-3 mt-3 md:mt-0 md:mr-8">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="px-3 py-2 w-full md:w-fit border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                </div>
            </div>
            <div className={"px-3 md:px-8"}>
                <div className={"w-full my-8 bg-white rounded-lg shadow-lg p-5"}>
                    <p className={"pb-3 text-xl"}>Link pentru invitarea elevilor in platforma:</p>
                    <div className={"flex flex-col md:flex-row gap-2"}>
                        <kbd
                            className={"flex-grow px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg break-all"}>
                            {
                                process.env.REACT_APP_BASE_LINK + ApplicationRoutes.registerStudentsEntry + invitationLink
                            }
                        </kbd>
                        <button onClick={handleClick}
                                className={"bg-accent-main-500 px-3 py-3 md:py-0 rounded-lg text-white"}>
                            Copiaza
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap gap-5 mt-8 mx-3 md:mx-8">
                {
                    isLoading ?
                        <div className={"mt-8 justify-center w-full"}><LoadingPlaceholder/></div> :
                        <ul className={"w-full flex flex-col gap-2"}>
                            {
                                (searchTerm.length === 0 ? students : searchedStudents).map((u, index) =>
                                    <ViewUserCard key={index} user={u} action={deleteStudent}
                                                  color={u.isActive ? "bg-error" : "bg-green-500"}
                                                  icon={<p>{u.isActive ? "Dezactiveaza" : "Activeaza"}</p>}/>)
                            }
                        </ul>
                }
            </div>
            <h1 className={"text-xl mt-8 mb-3 mx-3 md:mx-8"}>Acestea sunt cursurile disponibile pentru ei:</h1>
            <div className="flex flex-wrap gap-5 mx-3 md:mx-8">
                {
                    isLoadingStudentCourses ?
                        <div className={"mt-8 justify-center w-full"}><LoadingPlaceholder/></div> :
                        <ul className={"w-full flex flex-col sm:flex-row sm:flex-wrap gap-2"}>
                            {
                                courses.map((c, index) =>
                                    <div key={index} className={"w-[49%]"}>
                                        <ViewCourseCard
                                            course={{
                                                slug: c.slug,
                                                name: c.courseName,
                                                image: c.courseImage
                                            }}
                                            action={deleteStudent}
                                            color={"bg-error"}
                                            icon={null}
                                        />
                                    </div>)
                            }
                        </ul>
                }
            </div>
        </>
    );
}