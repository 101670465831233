import ChildRouter from "../../../../../../../commons/environment/ChildRouter";

export default class CoursesAdminRouter extends ChildRouter {
    static create = "/new";

    static edit= "/:slug/edit";
    static editLeading = "/edit";

    static view = "/:slug";
    static viewLeading = "/";
}