import React from "react";
import moment from "moment";
import Live from "../../../../../dashboardAdmin/functionals/components/livesAdmin/functionals/models/models/Live";
import {Link, useLocation} from "react-router-dom";

interface CourseCardProps {
    live: Live;
}

const LiveCard: React.FC<CourseCardProps> = ({live}) => {
    const location = useLocation();

    return (
        <div
            className={"w-full h-fit p-5 bg-white rounded-lg shadow flex sm:flex-row flex-col gap-2 items-center justify-between " + (live.on ? "border-2 border-error" : "border border-gray-200")}>
            <Link to={location.pathname + "/" + live.slug}>
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {
                        live.liveTitle
                    }
                </h5>
                <p className="font-normal text-sm text-gray-700 dark:text-gray-400">
                    {
                        moment(live.liveStart).format('DD MMMM YYYY HH:mm') + " - " + moment(live.liveEnd).format('HH:mm')
                    }
                </p>
            </Link>
            <div>
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Durata de intrare:
                </h5>
                <p className="font-normal text-sm text-gray-700 dark:text-gray-400">
                    {
                        live.joinMinutes
                    }
                </p>
            </div>
            {
                live.on ?
                    <div className={"font-bold text-error text-xl animate-pulse"}> Live </div>
                    :
                    <div className={"w-[109px]"}></div>
            }
            <Link to={location.pathname + "/" + live.slug}>
                <button
                    className={"p-2.5 flex gap-2 justify-center items-center text-center font-medium rounded-lg text-sm bg-accent-main-500 text-white"}>
                    Acceseaza live
                </button>
            </Link>
        </div>
    );
}

export default LiveCard;