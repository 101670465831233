import React from "react";
import {Route, Routes} from "react-router-dom";
import LivesAdminRouter from "../functionals/router/LivesAdminRouter";
import ListLives from "../functionals/components/ListLives";
import AdminRoute from "../../../../../../commons/functionals/authLogic/AdminRoute";
import CreateLives from "../functionals/components/CreateLives";

export default function LiveAdmin() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={LivesAdminRouter.root} element={<AdminRoute><ListLives/></AdminRoute>}/>
                <Route path={LivesAdminRouter.create} element={<AdminRoute><CreateLives/></AdminRoute>}/>
            </Routes>
        </div>
    )
}