import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../commons/environment/EnvironmentRoutes";
import Lesson from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Lesson";

export default class LessonsService {

    async getLesson(slug: string): Promise<Lesson> {
        const response: AxiosResponse<Lesson> = await axios.get(apiRoutes.baseLesson + slug, {withCredentials: true});
        return response.data;
    }
}