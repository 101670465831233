import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from './AuthContext';
import UserRoles from '../../component/header/functionals/models/UserRoles';
import ApplicationRoutes from "../../environment/AppRoutingName";
import RedirectToLoginPage from "../../../components/login/views/RedirectToLoginPage";

function AdminRoute({children}: { children: JSX.Element }) {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.role.name !== UserRoles.ROLE_SUPER_ADMIN) {
            navigate(ApplicationRoutes.dashboard, {replace: true});
        }
    }, [user]);

    return user && user.role.name === UserRoles.ROLE_SUPER_ADMIN ? children : <RedirectToLoginPage/>;
}

export default AdminRoute;
