import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../commons/environment/EnvironmentRoutes";

export default function Home() {
    const navigator = useNavigate();

    async function checkServerStatus(): Promise<string> {
        const response: AxiosResponse<string> = await axios.get(apiRoutes.health, {withCredentials: true});
        return response.data;
    }

    useEffect(() => {
        checkServerStatus()
            .then((_) => navigator(ApplicationRoutes.dashboard))
            .catch((_) => navigator(ApplicationRoutes.maintenance));
    }, [navigator]);

    return (<></>)
}