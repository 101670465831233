import React from "react";
import {Route, Routes} from "react-router-dom";
import AdminRoute from "../../../../../../commons/functionals/authLogic/AdminRoute";
import UsersAdminRoutes from "../functionals/routes/UsersAdminRoutes";
import ListUsers from "../functionals/components/ListUsers";
import ViewUser from "../functionals/components/ViewUser";

export default function UsersAdmin() {
    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={UsersAdminRoutes.root} element={<AdminRoute><ListUsers/></AdminRoute>}/>
                <Route path={UsersAdminRoutes.view} element={<AdminRoute><ViewUser/></AdminRoute>}/>
            </Routes>
        </div>
    );
}