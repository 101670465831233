import axios, {AxiosResponse} from "axios";
import CreateLiveRequest from "../models/requests/CreateLiveRequest";
import apiRoutes from "../../../../../../../commons/environment/EnvironmentRoutes";
import Live from "../models/models/Live";
import UserResponse from "../../../../../../../commons/component/header/functionals/models/UserResponse";
import GeneralResponse from "../../../../../../../commons/environment/models/GeneralResponse";

export default class LivesService {

    async listLives(): Promise<Live[]> {
        const response: AxiosResponse<Live[]> = await axios.get(apiRoutes.rootLives, {withCredentials: true});
        return response.data;
    }

    async stopLive(liveSlug: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post(apiRoutes.stopLive.replace("SLUG", liveSlug), null, {withCredentials: true});
        return response.data;
    }

    async createLive(request: CreateLiveRequest): Promise<void> {
        return await axios.post(apiRoutes.rootLives, request, {withCredentials: true});
    }

}