import QuestionsRequest from "./QuiestionsRequest";

interface QuizzesRequest {
    quizName: string;
    questionsRequestList: QuestionsRequest[];
}

export default QuizzesRequest

const defaultQuizzesRequest: QuizzesRequest = {
    quizName: '',
    questionsRequestList: [],
}

export {defaultQuizzesRequest};
