export default interface CreateCourseRequest {
    courseName: string;
    courseDescription: string;
    courseImage: string;
    objectsSlugs: { type: string, object: string }[];
}

const defaultCreateCourseRequest: CreateCourseRequest = {
    courseDescription: '',
    courseName: '',
    courseImage: '',
    objectsSlugs: []
};

export {defaultCreateCourseRequest};