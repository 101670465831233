import UserResponse from "../../../../../../../commons/component/header/functionals/models/UserResponse";
import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../../commons/environment/EnvironmentRoutes";
import {Page} from "../../../../../../../commons/functionals/pageable/Page";
import ComplexUser from "../models/ComplexUser";
import GeneralResponse from "../../../../../../../commons/environment/models/GeneralResponse";
import Course from "../../../coursesAdmin/functionals/models/models/Course";

export default class UsersService {

    async searchForUsersCourses(name: string, userSlug: string): Promise<Course[]> {
        const response: AxiosResponse<Course[]> = await axios.get(apiRoutes.usersSearchCourses.replace("SLUG", userSlug) + `?name=${name}`, {withCredentials: true});
        return response.data;
    }

    async searchUsers(name: string, page: number): Promise<Page<UserResponse>> {
        if (page < 0)
            page = 0;
        const response: AxiosResponse<Page<UserResponse>> = await axios.get(apiRoutes.usersSearchAll + `?name=${name}&page=${page}`, {withCredentials: true});
        return response.data;
    }

    async getUserDetails(slug: string): Promise<GeneralResponse<ComplexUser>> {
        const response: AxiosResponse<GeneralResponse<ComplexUser>> = await axios.get(apiRoutes.usersAll + "/" + slug, {withCredentials: true});
        return response.data;
    }

}