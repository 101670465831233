import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../environment/EnvironmentRoutes";
import UserResponse from "../models/UserResponse";

export default class HeaderService {

    async getUserDetails(): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axios.get<UserResponse>(apiRoutes.user, {withCredentials: true});
        return response.data;
    }

    async logoutUser(): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axios.post<UserResponse>(apiRoutes.logout, {}, {withCredentials: true});
        return response.data;
    }
}