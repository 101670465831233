import React from "react";
import {FaArrowRightLong} from "react-icons/fa6";
import {Link, useLocation} from "react-router-dom";
import CoursesUserRouter from "../router/CoursesUserRouter";
import Course from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Course";

interface CourseCardProps {
    course: Course;
}

const CourseCard: React.FC<CourseCardProps> = ({course}) => {

    const location = useLocation();
    return (
        <div className="w-full max-w-[400px] p-5">
            <div
                className="bg-white border border-gray-200 rounded-lg shadow">
                <Link to={location.pathname + CoursesUserRouter.viewLeading + course.slug}>
                    <div className="rounded-t-lg h-[250px] relative overflow-hidden">
                        <img className="absolute inset-0 w-full h-full object-cover" src={course.courseImage} alt=""/>
                    </div>
                </Link>
                <div className="p-5">
                    <Link to={location.pathname + CoursesUserRouter.viewLeading + course.slug}>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {
                                course.courseName
                            }
                        </h5>
                    </Link>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        {
                            course.courseDescription
                        }
                    </p>
                    <div className={"flex justify-center"}>
                        <Link to={location.pathname + CoursesUserRouter.viewLeading + course.slug}
                              className="inline-flex gap-2 hover:gap-3 transition-all items-center px-3 py-2 text-sm font-medium text-center border border-accent-main-200 text-accent-main-600 hover:text-white hover:bg-accent-main-500 rounded-lg">
                            Continua cursul
                            <FaArrowRightLong />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCard;