import React, {ReactNode} from "react";
import User from "../../../../../../../commons/component/header/functionals/models/User";
import {FaRegUserCircle} from "react-icons/fa";

interface ViewUserCardList {
    user: User;
    action: (slug: string) => void
    icon: ReactNode | null,
    color: string
}

const ViewUserCard: React.FC<ViewUserCardList> = ({user, action, icon, color}) => {
    return (
        <li className="rounded-lg p-3 sm:pb-4 list-none hover:bg-accent-main-50">
            <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex text-xl">
                    <FaRegUserCircle/>
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {user.username}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {user.email}
                    </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-accent-main-900 bg-accent-main-200 border-accent-main-300 border px-2 rounded-md">
                    {user.role.name.replaceAll("_", " ").replace("ROLE", "")}
                </div>
                {
                    icon === null ?
                        <></> :
                        <button
                            className={color + " cursor-pointer flex justify-center items-center text-white relative h-8 max-h-[40px] w-8 max-w-[40px] select-none rounded-xl text-center align-middle font-sans text-xs font-medium uppercase transition-all"}
                            type="button"
                            onClick={() => action(user.slug)}>
                            {icon}
                        </button>
                }
            </div>
        </li>
    );
}

export default ViewUserCard;