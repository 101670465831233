import React, {ReactNode} from "react";

interface ViewCourseCardList {
    course: { slug: string, name: string, image: string };
    action: (slug: string) => void
    icon: ReactNode | null,
    color: string
}

const ViewCourseCard: React.FC<ViewCourseCardList> = ({course, action, icon, color}) => {
    return (
        <li className="rounded-lg flex list-none hover:bg-accent-main-50 h-16 my-2">
            <div className="rounded-l-lg flex w-[100px] h-full overflow-hidden">
                <img className="w-full h-full object-cover"
                     src={course.image}
                     alt=""/>
            </div>
            <div className="p-3 w-full flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {course.name}
                    </p>
                </div>
                {
                    icon === null ?
                        <></> :
                        <button
                            className={color + " cursor-pointer flex justify-center items-center text-white relative h-8 max-h-[40px] w-8 max-w-[40px] select-none rounded-xl text-center align-middle font-sans text-xs font-medium uppercase transition-all"}
                            type="button"
                            onClick={() => action(course.slug)}>
                            {icon}
                        </button>
                }
            </div>
        </li>
    );
}

export default ViewCourseCard;