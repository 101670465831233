import React, {useEffect, useState} from "react";
import CreatedProjects from "../models/CreatedProjects";
import CreateProjectService from "../service/CreateProjectService";
import {toast, Toaster} from "react-hot-toast";
import Breadcrumbs from "../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";
import ProjectItem from "../iterables/ProjectItem";
import {IoCloudUploadOutline} from "react-icons/io5";
import LoadingText from "../../../../../../commons/component/loading/views/LoadingText";
import SplittedText from "../../../../../../commons/component/splited_text/SplittedText";

export default function ListProjectsAndCreate() {

    const [projects, setProjects] = useState<CreatedProjects[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [componentLoading, setComponentLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [resourceLink, setResourceLink] = useState<string | null>(null);
    const createProjectService = new CreateProjectService();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            setResourceLink(null);
        }
    };

    const computeStyles = () => {
        return "flex flex-col items-center justify-center w-full h-64 border-2 border-" + (file == null ? "gray" : "accent-main") + "-300 border-dashed rounded-lg cursor-pointer bg-" + (file == null ? "gray" : "accent-main") + "-50 hover:bg-" + (file == null ? "gray" : "accent-main") + "-100";
    }

    useEffect(() => {
        setIsLoading(true);
        createProjectService.getMyProjects()
            .then(response => setProjects(response))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, []);

    const createProject = () => {
        if (file == null && resourceLink == null) {
            toast.error("Incarca un fisier sau un link pentru a crea un proiect!");
            return;
        }

        setComponentLoading(true);
        createProjectService.createProject(file, resourceLink)
            .then((response) => {
                setFile(null);
                setResourceLink(null);
                setProjects([...projects, response]);
                toast.success("Proiect creat!");
            })
            .catch((err) => toast.error(err.response.data.message ?? "Ai deja un proiect in asteptare!"))
            .finally(() => setComponentLoading(false));
    }

    return (
        <div className={"px-3 md:px-8 w-full pb-6"}>
            <div><Toaster/></div>
            <Breadcrumbs paths={["Proiectele mele"]}/>
            <h1 className={"text-xl md:text-4xl font-semibold"}>Incarca imagine implementare:</h1>
            <div className="flex items-center justify-center w-full mt-3">
                <label htmlFor="dropzone-file"
                       className={computeStyles()}>
                    {
                        file == null ?
                            <>
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <IoCloudUploadOutline
                                        className={"w-10 h-10 text-gray-500"}/>
                                    <p className={"font-semibold mb-2 text-sm text-gray-500"}>
                                        Click pentru a incarca
                                    </p>
                                    <p className={"text-xs text-gray-500"}>SVG, PNG, JPG</p>
                                </div>
                            </>
                            :
                            <>
                                <img src={URL.createObjectURL(file)} className={"w-52 h-32 rounded-lg object-cover"}
                                     alt=""/>
                                <p className="font-semibold text-sm text-accent-main-500">
                                    {file!.name}
                                </p>
                                <p className="text-xs text-accent-main-500">
                                    {file!.type}
                                </p>
                            </>
                    }
                    <input id="dropzone-file" type="file" className="hidden" accept="image/*"
                           onChange={handleFileChange}/>
                </label>
            </div>
            <SplittedText text={"SAU"}/>
            <input type="text" placeholder="Link catre resurse"
                   onChange={(e) => {
                       setResourceLink(e.target.value);
                       setFile(null);
                   }}
                   value={resourceLink ?? ""}
                   className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            <div className={"w-full flex justify-end"}>
                <button
                    disabled={componentLoading}
                    onClick={createProject}
                    className={"transition-all mb-3 w-fit p-2.5 flex gap-2 justify-center items-center text-center font-medium rounded-lg text-sm bg-accent-main-500 text-white"}>
                    {
                        componentLoading ?
                            <LoadingText color={"#ffffff"}/> :
                            "Trimite resurse"
                    }
                </button>
            </div>
            <h1 className={"text-xl md:text-4xl font-semibold"}>Acestea sunt proiectele tale:</h1>
            <div className="flex flex-col gap-2 w-full mt-5">
                {isLoading ?
                    <LoadingPlaceholder/> : projects.map((project, index) => (
                        <div key={index}>
                            <ProjectItem project={project}/>
                        </div>
                    ))}
            </div>
        </div>
    );
}