import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CoursesAdminRouter from "../router/CoursesAdminRouter";
import {IoIosAddCircleOutline} from "react-icons/io";
import CoursesService from "../service/CoursesService";
import Course from "../models/models/Course";
import CourseCard from "../iterables/CourseCard";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import BackButton from "../../../../../../../commons/component/BackButton";
import DashboardAdminRouter from "../../../../router/DashboardAdminRouter";
import {toast} from "react-hot-toast";

export default function ListCourses() {
    const location = useLocation();
    const coursesService = new CoursesService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [courses, setCourses] = useState<Course[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setIsLoading(true);
        coursesService.listCourses().then((response) => {
            setCourses(response);
        })
            .catch((_) => setError(true))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (searchTerm.length !== 0) {
            const delayDebounceFn = setTimeout(() => search(searchTerm), 500);
            return () => clearTimeout(delayDebounceFn);
        } else {
            setIsLoading(false);
        }
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setSearchTerm(event.target.value);
    };

    const search = (term: string) => {
        coursesService.search(term).then((response) => setCourses(response)).finally(() => setIsLoading(false));
    }

    const deleteCourse = (slug: string) => {
        coursesService.deleteCourse(slug)
            .then((_) => {
                setCourses(prevState => prevState.filter(c => c.slug !== slug));
                toast.success("Curs sters cu success");
            })
            .catch((_) => {
                toast.error("Eroare la stergerea cursului!")
            });
    }

    return (
        <>
            <div className={"px-3 md:px-8"}>
                <Breadcrumbs paths={["Cursuri"]}/>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between">
                <div className="flex md:justify-center items-center gap-3 px-3 md:px-8 text-xl md:text-4xl font-bold">
                    <BackButton slug={DashboardAdminRouter.courses} isFull={false} isSkinny={true}/>
                    <p>Cursurile din platforma</p>
                </div>
                <div className="flex gap-2 justify-items-stretch md:justify-center items-center mx-3 md:mr-8">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full md:w-[200px] px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    <Link
                        to={location.pathname + CoursesAdminRouter.create}
                        className="flex gap-2 justify-center items-center text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        <IoIosAddCircleOutline className={"text-lg"}/> Creaza
                    </Link>
                </div>
            </div>
            <div className="flex flex-wrap md:justify-normal justify-center">
                {
                    isLoading ?
                        <div className={"mt-8 w-full justify-center"}><LoadingPlaceholder/></div> :
                        courses.map((c, index) => <CourseCard key={index} course={c} deleteCourse={deleteCourse}/>)
                }
            </div>
        </>
    );
}