import React from "react";
import {Route, Routes} from "react-router-dom";
import StudentsTeacherRouter from "../functionals/router/StudentsTeacherRouter";
import ListStudents from "../functionals/components/ListStudents";
import PrivateRoute from "../../../../../commons/functionals/authLogic/PrivateRoute";

export default function StudentsRoot() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={StudentsTeacherRouter.root} element={<PrivateRoute><ListStudents/></PrivateRoute>}/>
            </Routes>
        </div>
    )
}