import React from "react";
import {Route, Routes} from "react-router-dom";
import ValidateProjectRouter from "../functionals/router/ValidateProjectRouter";
import ListUnapprovedProjects from "../functionals/components/ListUnapprovedProjects";
import AdminRoute from "../../../../../../commons/functionals/authLogic/AdminRoute";

export default function ValidateProjectRoot() {

    return (
        <div className={"pt-[50px] bg-background-color"}>
            <Routes>
                <Route path={ValidateProjectRouter.root} element={<AdminRoute><ListUnapprovedProjects/></AdminRoute>}/>
            </Routes>
        </div>
    )
}