import React from "react";

export default function Dashboard() {

    return (
        <div className={"pt-[60px] bg-background-color"}>
            <section className={"w-full p-5"}>
                <div className={"bg-white p-3 shadow-lg rounded-lg"}>
                    <h1 className={"text-xl md:text-4xl font-semibold"}>
                        Anunt important!
                    </h1>
                    <div className={"mt-2"}>
                        <p>Dragi profesori,</p>
                        <br/>
                        <p>Vă mulțumim că sunteți alături de noi.</p>
                        <p>În perioada 19-21 Noiembrie ora 18.30 organizăm training-ul live Eroii Internetului.</p>
                        <p>Dacă aveți întrebări va rugăm să ne scrieți la webinar@adfaber.org.</p>
                    </div>
                </div>
            </section>
            {/*<section className={"w-full"}>*/}
            {/*    <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Statisticile tale:</h1>*/}
            {/*</section>*/}
            {/*<section className={"w-full mt-7"}>*/}
            {/*    <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Cursurile neterminate de tine:</h1>*/}
            {/*</section>*/}
            {/*<section className={"w-full mt-7"}>*/}
            {/*    <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Rezultate de la quiz-uri:</h1>*/}
            {/*</section>*/}
        </div>
    )
}